import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Page } from './Page'
import { AdminBanner } from '../admin/AdminBanner'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minWidth: '1040px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
  },
}))

export const AdminPage: React.FC<{
  children: React.ReactNode
  title: string
}> = ({ children, title }) => {
  const classes = useStyles()
  return (
    <Page title={title}>
      <Box display="flex" flexDirection="column">
        <AdminBanner />
        <Box className={classes.container}>{children}</Box>
      </Box>
    </Page>
  )
}
