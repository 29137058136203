import React, { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxButton,
  NxTypography,
  NxModal,
  NxCheckbox,
} from '@playvs-inc/nexus-components'
import { useForm } from 'react-hook-form'
import { CaretDown, CaretUp } from '@playvs-inc/nexus-icons'

const useStyles = makeStyles((theme) => ({
  scoreSelector: {
    userSelect: 'none',
  },
  topTeamContainer: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.BorderMedium}`,
  },
}))

interface Props {
  onSubmit: (matchId: string, teams: TeamInput[]) => void
  onDelete: (matchId: string) => void
  matchId: string
  topTeam: TeamInput
  bottomTeam: TeamInput
  open: boolean
  setOpen: (open: boolean) => void
}

export type TeamInput = {
  id: string
  name?: string
  seed?: number
  score: number
  winner: boolean
}

type EventMatchResultInput = {
  matchId: string
  topTeamId: string
  topTeamScore: number
  topTeamIsWinner: boolean
  bottomTeamId: string
  bottomTeamScore: number
  bottomTeamIsWinner: boolean
}

const cleanupValues = (
  matchId: string,
  topTeam: TeamInput,
  bottomTeam: TeamInput
): EventMatchResultInput => {
  return {
    matchId,
    topTeamId: topTeam.id,
    topTeamScore: topTeam.score ?? 0,
    topTeamIsWinner: topTeam.winner ?? false,
    bottomTeamId: bottomTeam.id,
    bottomTeamScore: bottomTeam.score ?? 0,
    bottomTeamIsWinner: bottomTeam.winner ?? false,
  }
}

export const EventBracketMatchResultModal: React.FC<Props> = ({
  onSubmit,
  onDelete,
  matchId,
  topTeam,
  bottomTeam,
  open,
  setOpen,
}) => {
  const classes = useStyles()
  const { handleSubmit, watch, getValues, setValue, reset } =
    useForm<EventMatchResultInput>({
      defaultValues: cleanupValues(matchId, topTeam, bottomTeam),
    })

  const handle = handleSubmit(
    async (input: EventMatchResultInput): Promise<void> => {
      onSubmit(input.matchId, [
        {
          id: input.topTeamId,
          score: input.topTeamScore,
          winner: input.topTeamIsWinner,
        },
        {
          id: input.bottomTeamId,
          score: input.bottomTeamScore,
          winner: input.bottomTeamIsWinner,
        },
      ])
      setOpen(false)
    }
  )

  const handleReset = async (): Promise<void> => {
    onDelete(matchId)
    setOpen(false)
  }

  useEffect(() => {
    reset(cleanupValues(matchId, topTeam, bottomTeam))
  }, [matchId, topTeam, bottomTeam])

  const onClose = (): void => {
    setOpen(false)
  }

  return (
    <NxModal
      onClose={onClose}
      open={open}
      showTopRightClose
      size="small"
      title="Select a Winner"
    >
      <Box
        component="form"
        /* @ts-expect-error: valid prop */
        method="post"
        onSubmit={handle}
      >
        <Box
          alignItems="center"
          className={classes.topTeamContainer}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <NxTypography variant="h4">{topTeam.seed}</NxTypography>
            </Box>
            <NxTypography variant="h4">{topTeam.name}</NxTypography>
          </Box>
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box
              className={classes.scoreSelector}
              display="flex"
              flexDirection="column"
              mr={2}
            >
              <CaretUp
                onClick={() => {
                  const currentScore = getValues().topTeamScore
                  setValue('topTeamScore', currentScore + 1)
                }}
              />
              <CaretDown
                onClick={() => {
                  const currentScore = getValues().topTeamScore
                  setValue('topTeamScore', currentScore - 1)
                }}
              />
            </Box>
            <Box textAlign="left" width="30px">
              <NxTypography variant="h4">{watch('topTeamScore')}</NxTypography>
            </Box>
            <NxCheckbox
              defaultChecked={getValues().topTeamIsWinner}
              name="topTeamIsWinner"
              onChange={(event): void =>
                setValue('topTeamIsWinner', event.target.checked)
              }
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <NxTypography variant="h4">{bottomTeam.seed}</NxTypography>
            </Box>
            <NxTypography variant="h4">{bottomTeam.name}</NxTypography>
          </Box>
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box
              className={classes.scoreSelector}
              display="flex"
              flexDirection="column"
              mr={2}
            >
              <CaretUp
                onClick={() => {
                  const currentScore = getValues().bottomTeamScore
                  setValue('bottomTeamScore', currentScore + 1)
                }}
              />
              <CaretDown
                onClick={() => {
                  const currentScore = getValues().bottomTeamScore
                  setValue('bottomTeamScore', currentScore - 1)
                }}
              />
            </Box>
            <Box textAlign="left" width="30px">
              <NxTypography variant="h4">
                {watch('bottomTeamScore')}
              </NxTypography>
            </Box>
            <NxCheckbox
              defaultChecked={getValues().bottomTeamIsWinner}
              name="bottomTeamIsWinner"
              onChange={(event): void =>
                setValue('bottomTeamIsWinner', event.target.checked)
              }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box mr={2}>
            <NxButton
              label="Reset Score"
              onClick={handleReset}
              type="button"
              variant="text"
            />
          </Box>
          <NxButton label="Submit Score" type="submit" variant="primary" />
        </Box>
      </Box>
    </NxModal>
  )
}
