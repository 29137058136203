/* istanbul ignore file */
// This takes an unusally large amount of low level mocking in order to test.
// At this point it would be more test code written against the specific
// implementation to be worth the effort/return from such a test.

import { ApolloLink } from '@apollo/client'
import { liveEnv } from '@plvs/live-fe/env'
import { createUploadLink } from 'apollo-upload-client'

export const uploadLink = (jwt: string): ApolloLink =>
  createUploadLink({
    headers: {
      authorization: jwt ? `Bearer ${jwt}` : '',
      'Apollo-Require-Preflight': 'true',
    },
    uri: liveEnv.GRAPHQL_URI,
  }) as unknown as ApolloLink
