import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { useImageLoader } from '@plvs/live-fe/utils/imageUtils'
import LoginLeftBanner from '@plvs/live-fe/assets/LoginLeftBanner.svg'
import PlayVSLiveLogo from '@plvs/live-fe/assets/PlayVSLiveLogo.svg'
import {
  NxButton,
  NxTextInput,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { Unhide, Hide } from '@playvs-inc/nexus-icons'
import { isInputValid } from '@plvs/live-fe/utils/formUtils'
import * as yup from 'yup'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { LoginInput, useLoginMutation } from '@plvs/live-fe/graphql'
import { Path } from '../paths'
import { Cookie } from '../../client'
import { safeSetCookie } from '../../utils/routingUtils'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  banner: {
    objectFit: 'cover',
    width: '100%',
  },
  bannerContainer: ({
    isLoaded,
  }: {
    isLoaded: boolean
  }): CreateCSSProperties => ({
    display: 'flex',
    willChange: 'transform',
    width: '50vw',
    height: '100vh',
    transition: 'all 1.2s ease-in-out',
    opacity: isLoaded ? 1 : 0,
    transformOrigin: 'center top',
  }),
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const LoginFormSchema = yup.object().shape({
  email: yup.string().email().required('Enter a username.'),
  password: yup.string().required('Enter a password.'),
})

export const LoginPage: React.FC = () => {
  const theme = useTheme()
  const [_, setCookie] = useCookies()
  const [mutate, { loading: loginLoading }] = useLoginMutation()
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<Error>()
  const ShowPasswordIcon = showPassword ? Unhide : Hide

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<LoginInput>()
  const isValid = isInputValid(watch(), LoginFormSchema)
  const canSubmit = isValid && !loginLoading

  const { isLoaded: bannerIsLoaded, imageSrc: bannerImageSrc } = useImageLoader(
    {
      src: LoginLeftBanner,
    }
  )
  const classes = useStyles({ isLoaded: bannerIsLoaded })

  const onSubmit = handleSubmit(async (input: LoginInput): Promise<void> => {
    try {
      const loginResponse = ((await mutate({ variables: { input } })) || {})
        ?.data?.login

      if (!loginResponse) {
        throw new Error('Empty response received from server')
      }
      safeSetCookie(setCookie, Cookie.Token, loginResponse.accessToken)
      window.location.replace(Path.Events)
    } catch (e: any) {
      setError(e)
    }
  })

  return (
    <Box className={classes.container}>
      <Box className={classes.bannerContainer}>
        {bannerIsLoaded && bannerImageSrc && (
          <img
            alt="Live Banner"
            className={classes.banner}
            src={bannerImageSrc}
          />
        )}
      </Box>
      <Box
        className={classes.formContainer}
        component="form"
        /* @ts-expect-error: valid prop */
        method="post"
        onSubmit={onSubmit}
        pb={20}
      >
        <Box mb={2}>
          <img alt="Live Logo" src={PlayVSLiveLogo} />
        </Box>
        <Box my={2} textAlign="center">
          <NxTypography colorToken="ColorTextError" variant="body3">
            {error?.message}
          </NxTypography>
        </Box>
        <NxTextInput
          {...register('email')}
          fullWidth
          helperText={errors.email?.message?.toString()}
          label="Username"
          name="email"
          type="email"
          variant={errors.email ? 'error' : 'default'}
        />
        <NxTextInput
          {...register('password')}
          endAdornment={
            <Box mr={1.375}>
              <ShowPasswordIcon
                color={theme.palette.ColorIconBase}
                data-cy="showPasswordIcon"
                data-testid="showPasswordIcon"
                onClick={(): void => setShowPassword(!showPassword)}
              />
            </Box>
          }
          fullWidth
          helperText={errors?.email?.message?.toString()}
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant={errors.email ? 'error' : 'default'}
        />
        <NxButton
          disabled={!canSubmit}
          fullWidth
          label="Sign In"
          type="submit"
          variant="primary"
        />
      </Box>
    </Box>
  )
}
