import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Path } from './paths'
import { LoginPage } from './login/LoginPage'
import { EventsPage } from './events/EventsPage'
import { AuthRoute } from './route/AuthRoute'
import { EventPublicPage } from './event/EventPublicPage'
import { EventPage } from './event/EventPage'
import { EventPasswordPage } from './event/EventPasswordPage'

export const Pages: React.FC = () => (
  <Routes>
    <Route element={<LoginPage />} path={Path.Login} />
    <Route element={<AuthRoute />}>
      <Route element={<Navigate replace to={Path.Events} />} path="" />
      <Route
        element={<EventPage key="create-event" />}
        path={Path.EventCreate}
      />
      <Route element={<EventPage key="edit-event" />} path={Path.EventEdit} />
      <Route element={<EventsPage />} path={Path.Events} />
    </Route>
    <Route element={<EventPasswordPage />} path={Path.EventPassword} />
    <Route element={<EventPublicPage />} path={Path.EventPublic} />
    <Route element={<Navigate replace to={Path.Events} />} path="*" />
  </Routes>
)
