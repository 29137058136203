import {
  Cookie as CookieProp,
  CookieGetOptions,
  CookieSetOptions,
} from 'universal-cookie'
import { Cookie } from '../client'
import { liveEnv } from '../env'

export type GetCookieFn = (name: Cookie, options?: CookieGetOptions) => string

export type SetCookieFn = (
  name: Cookie,
  value: string,
  options?: CookieSetOptions
) => void

export type RemoveCookieFn = (name: Cookie, options?: CookieSetOptions) => void

// interfaces

export interface CookieProps {
  cookies: CookieProp
}

const NINETY_DAYS_IN_SECONDS = 24 * 60 * 60 * 90

export const safeSetCookie = (
  fn: SetCookieFn,
  name: Cookie,
  value: string,
  domain?: string,
  options: CookieSetOptions = {}
): void => {
  if (name === Cookie.Token) {
    fn(name, value, {
      maxAge: NINETY_DAYS_IN_SECONDS,
      path: '/',
      secure: liveEnv.API_ENV !== 'development',
      domain: liveEnv.COOKIE_DOMAIN,
      ...options,
    })
  } else {
    fn(name, value, {
      path: '/',
      domain: domain ?? liveEnv.COOKIE_DOMAIN,
      ...options,
    })
  }
}

export const safeRemoveCookie = (
  fn: RemoveCookieFn,
  name: Cookie,
  options: CookieSetOptions = {}
): void => {
  fn(name, {
    path: '/',
    domain: liveEnv.COOKIE_DOMAIN,
    ...options,
  })
}
