import { Box, ThemeProvider, makeStyles } from '@material-ui/core'
import { EventBracketContainer } from '@plvs/live-fe/components/event/EventBracketContainer'
import { Page } from '@plvs/live-fe/components/layout/Page'
import { EventStatus, useEventBySlugQuery } from '@plvs/live-fe/graphql'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  NxButton,
  NxEmptyState,
} from '@playvs-inc/nexus-components'
import PlayVSBanner from '@plvs/live-fe/assets/PlayVSBanner.svg'
import { useImageLoader } from '@plvs/live-fe/utils/imageUtils'
import { NxSpot } from '@playvs-inc/nexus-spots'
import { PlayVsDarkTheme } from '@playvs-inc/nexus-theme'
import { EventQRCode } from '@plvs/live-fe/components/event/EventQRCode'
import { useBreakpointSm } from '@plvs/live-fe/utils/layoutUtils'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#161616',
    minHeight: '100vh',
  },
  laterContainer: {
    width: '100vw',
    '& img': {
      objectFit: 'cover',
      width: '100%',
    },
  },
  emptyState: {
    backgroundColor: '#161616',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  qrCode: {
    position: "fixed",
    bottom: '20px',
    right: '20px',
  }
}))

export const EventPublicPage: React.FC = () => {
  const isMobile = useBreakpointSm()
  const classes = useStyles()
  const { slug } = useParams()
  const { data, loading } = useEventBySlugQuery({
    variables: {
      slug: slug ?? '',
    },
    skip: !slug,
  })
  const event = data?.eventBySlug
  const bracket = data?.eventBySlug?.brackets
    ? data?.eventBySlug?.brackets[0]
    : null
  const status = data?.eventBySlug.status
  const { imageSrc: bannerImageSrc } = useImageLoader({
    src: PlayVSBanner,
  })

  return (
    <Page title="Event">
      {loading ? (
        <></>
      ) : (
        <Box className={classes.container}>
          {status !== EventStatus.Published || !bracket || !event ? (
            <Box className={classes.laterContainer}>
              <img alt="Live Banner" src={bannerImageSrc} />
              <ThemeProvider theme={PlayVsDarkTheme}>
                <NxEmptyState
                  className={classes.emptyState}
                  cta={
                    <NxButton
                      label="Refresh Page"
                      onClick={(): void => {
                        window.location.reload()
                      }}
                      variant="secondary"
                    />
                  }
                  data-testid="empty"
                  spot={
                    <NxSpot
                      domain="Arena"
                      height={132}
                      size="large"
                      variant="secondary"
                      width={132}
                    />
                  }
                  subtitle="This event does not exist or has not started. Please check back later."
                  title="Event Not Found"
                />
              </ThemeProvider>
            </Box>
          ) : (
            <>
              <EventBracketContainer
                bracket={bracket}
                event={event}
                eventTeams={event?.teams ?? []}
              />
              {
                !isMobile &&
                <Box className={classes.qrCode}>
                  <EventQRCode url={window.location.href} />
                </Box>
              }
            </>
          )}
        </Box>
      )}
    </Page>
  )
}
