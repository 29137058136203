/* istanbul ignore file */
// This takes an unusally large amount of low level mocking in order to test.
// At this point it would be more test code written against the specific
// implementation to be worth the effort/return from such a test.

import { ApolloCache, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { Cookie } from '@plvs/live-fe/client'
import { safeRemoveCookie } from '@plvs/live-fe/utils/routingUtils'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const forceLogoutLink = (
  cache: ApolloCache<NormalizedCacheObject>
): ApolloLink =>
  onError(({ graphQLErrors }) => {
    if (!graphQLErrors || graphQLErrors.length < 1) {
      return
    }

    // If we have a Forbidden error force logout the user and redirect to forbidden page.
    if (graphQLErrors.find((err) => err?.extensions?.code === 'FORBIDDEN')) {
      safeRemoveCookie(cookies.remove.bind(cookies), Cookie.Token)
      // Clear the cache of any data fetched while the user was logged in.
      cache.reset()
      window?.location?.replace('/')
    }
  })
