/* istanbul ignore file */
// Cannot effectively unit test.

/**
 * Rules below define qualifications for the uniqueness of returned data,
 * preventing Apollo from caching objects.
 *
 * NOTE: everytime these types are requested through our API, we must also ask for these properties regardless of
 * using them in the UI, so that apollo can cache the responses correctly.
 *
 * https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
 */

export const typePolicies = {}
