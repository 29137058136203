import { Box, makeStyles } from '@material-ui/core'
import { NxHeaderCard } from '@playvs-inc/nexus-components'
import { EventsTable } from '@plvs/live-fe/components/events/EventsTable'
import { TablePagination } from '@plvs/live-fe/components/events/Pagination'
import { AdminPage } from '@plvs/live-fe/components/layout/AdminPage'
import {
  EventStatus,
  useEventsQuery,
  useUpdateEventStatusMutation,
} from '@plvs/live-fe/graphql'
import React, { useState } from 'react'

const LIMIT = 10

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  header: {
    background: theme.palette.OverlayColorOpBlack3,
    color: theme.palette.ColorIconInvert,
  },
}))

export const EventsPage: React.FC = () => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const offset = (page - 1) * LIMIT
  const getNumPages = (itemsCount: number): number =>
    Math.ceil(itemsCount / LIMIT)

  const { data, loading, refetch } = useEventsQuery({
    variables: {
      input: {
        limit: LIMIT,
        offset,
      },
    },
  })
  const totalCount = data?.events.total ?? 0
  const numPages = getNumPages(totalCount)

  const [mutation, { loading: isMutating }] = useUpdateEventStatusMutation()

  const onUpdate = async (id: string, status: EventStatus): Promise<void> => {
    await mutation({
      variables: {
        input: {
          id,
          status,
        },
      },
    })
    await refetch()
  }

  return (
    <AdminPage title="Events">
      {loading || isMutating ? (
        <></>
      ) : (
        <Box className={classes.container}>
          <Box mb={4}>
            <NxHeaderCard headerClassName={classes.header} title="Events">
              <EventsTable
                events={data?.events.events ?? []}
                onUpdateStatus={onUpdate}
              />
            </NxHeaderCard>
          </Box>
          <TablePagination
            numPages={numPages}
            page={page}
            setPage={setPage}
            totalCount={totalCount}
          />
        </Box>
      )}
    </AdminPage>
  )
}
