import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import DefaultBanner from '@plvs/live-fe/assets/DefaultBanner.svg'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useImageLoader } from '@plvs/live-fe/utils/imageUtils'
import { BracketMatchStatus, BracketType } from '@plvs/live-fe/graphql'
import { Draggable } from '../layout/Draggable'
import { TeamInput } from './EventBracketMatchResultModal'
import { groupBy } from 'lodash'
import { EventBracket } from './EventBracket'

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  container: {
    backgroundColor: '#161616',
  },
  titleContainer: {
    paddingTop: theme.spacing(8),
    padding: theme.spacing(4),
    position: 'relative',
  },
  bracketContainer: {
    overflowX: 'auto',
  },
  innerContainer: {
    maxWidth: '1440px',
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    backgroundPosition: 'top center',
  },
  gradient: {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: `rgba(17, 17, 17, .7)`,
    background: `linear-gradient(90deg, #161616 12.5%, rgba(38, 38, 38, 0) 50%, #161616 87.5%),
      linear-gradient(0deg, #161616 10%, rgba(38, 38, 38, 0) 30%)`,
  },
  image: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  subtitle: {
    textTransform: 'uppercase',
  },
}))

type BracketResult = {
  id: string
  matchId: string
  nextMatchId: string | null
  roundNumber: number
  ordinalNumber: number
  bracketMatchTeams: any[]
  status: BracketMatchStatus
}

type Event = {
  bannerUrl: string | null
  bracketBackgroundUrl: string | null
  themeColorCode: string
  name: string
  esport: string
}

type Bracket = {
  size: number
  type: BracketType
  results: BracketResult[]
}

type EventTeam = {
  id: string
  name: string
  seed: number | null
}

interface EventBracketProps {
  bracket?: Bracket
  event: Event
  eventTeams?: EventTeam[]
  editable?: boolean
  onSubmit?: (matchId: string, teams: TeamInput[]) => void
  onDelete?: (matchId: string) => void
}

function getTypeFormatted(type?: BracketType): string {
  if (type === BracketType.SingleElimination) {
    return '• Single Elimination'
  }
  if (type === BracketType.DoubleElimination) {
    return '• Double Elimination'
  }
  return ''
}

export const EventBracketContainer: React.FC<EventBracketProps> = ({
  bracket,
  event,
  eventTeams,
  editable,
  onSubmit,
  onDelete,
}) => {
  const classes = useStyles()

  const { imageSrc: bannerImageSrc } = useImageLoader({
    src: DefaultBanner,
  })

  const results = bracket?.results
  const bracketType = bracket?.type
  const matches = results?.map((result) => {
    const bracketPosition = result.matchId.includes('upper') ? 'Upper' : 'Lower'
    return {
      id: result.id,
      bracket: bracketPosition,
      status: result.status,
      name:
        bracketType === BracketType.SingleElimination
          ? `Round ${result.roundNumber + 1} - 
        Match ${result.ordinalNumber + 1}`
          : `${bracketPosition} Round ${result.roundNumber + 1} - 
        Match ${result.ordinalNumber + 1}`,
      nextMatchId: result.nextMatchId,
      participants: result.bracketMatchTeams.map((team) => {
        const eventTeam = eventTeams?.find((t) => t.id === team.teamId)
        if (eventTeam) {
          return {
            seed: eventTeam.seed,
            id: team.id,
            teamId: eventTeam.id,
            name: eventTeam.name,
            winner:
              result.status === BracketMatchStatus.Complete
                ? team.matchWinner
                : undefined,
            score:
              result.status === BracketMatchStatus.Complete
                ? team.matchScore
                : undefined,
          }
        }
        return {
          id: team.id,
        }
      }),
      tournamentRoundText: result.roundNumber + 1,
      roundNumber: result.roundNumber,
      matchNumber: result.ordinalNumber,
    }
  })

  function getBrackets(): React.ReactNode {
    if (bracketType === BracketType.DoubleElimination) {
      const upperGroupedBrackets = groupBy(groupBy(matches, 'bracket')['Upper'], 'roundNumber')
      const lowerGroupedBrackets = groupBy(groupBy(matches, 'bracket')['Lower'], 'roundNumber')
      return (
        <>
          <EventBracket upper={true} bracketType={BracketType.DoubleElimination} matchesGrouped={upperGroupedBrackets} themeColorCode={event.themeColorCode} editable={editable} onDelete={onDelete} onSubmit={onSubmit} />
          <EventBracket bracketType={BracketType.DoubleElimination} matchesGrouped={lowerGroupedBrackets} themeColorCode={event.themeColorCode} editable={editable} onDelete={onDelete} onSubmit={onSubmit} />
        </>
      )
    }
    else {
      const groupedBrackets = groupBy(matches, 'roundNumber')
      return (
        <>
          <EventBracket bracketType={BracketType.SingleElimination} matchesGrouped={groupedBrackets} themeColorCode={event.themeColorCode} editable={editable} onDelete={onDelete} onSubmit={onSubmit} />
        </>
      )
    }
  }

  return (
    <Box>
      <Box className={classes.bannerContainer}>
        <img
          alt="Banner"
          src={event.bannerUrl ? event.bannerUrl : bannerImageSrc}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          {event?.bracketBackgroundUrl && (
            <Box className={classes.image}>
              <img alt="" src={event.bracketBackgroundUrl} />
              <Box className={classes.gradient} />
            </Box>
          )}
          <Box className={classes.titleContainer}>
            <Box mb={2}>
              <NxTypography colorToken="OverlayColorTextAlt" variant="display">
                {event.name}
              </NxTypography>
            </Box>
            <NxTypography
              className={classes.subtitle}
              colorToken="OverlayColorTextAlt"
              variant="subtitle2"
            >
              {event.esport} {getTypeFormatted(bracketType)}
            </NxTypography>
          </Box>
          <Draggable arrowColor={event.themeColorCode}>
            <Box className={classes.bracketContainer}>
              {getBrackets()}
            </Box>
          </Draggable>
        </Box>
      </Box>
    </Box>
  )
}
