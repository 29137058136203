import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { ApolloLink, split } from '@apollo/client'
import { liveEnv } from '@plvs/live-fe/env'
import { uploadLink } from './uploadLink'

export const splitLink = (jwt: string): ApolloLink => {
  const authorization = jwt ? `Bearer ${jwt}` : undefined

  return typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        new GraphQLWsLink(
          createClient({
            url: liveEnv.GRAPHQL_WS_URL,
            connectionParams: {
              authorization,
            },
          })
        ),
        uploadLink(jwt)
      )
    : uploadLink(jwt)
}
