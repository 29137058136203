/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box } from '@material-ui/core'
import React from 'react'
import {
  NxTypography,
} from '@playvs-inc/nexus-components'
import { QRCode } from 'react-qrcode-logo'

interface EventQRCodeProps {
  url: string
}

export const EventQRCode: React.FC<EventQRCodeProps> = ({url}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="125px">
      <Box mr={2}>
        <QRCode value={url} quietZone={5} logoImage='/logo.png' logoWidth={20} logoPadding={1} size={100} />
      </Box>
      <NxTypography variant="body3" colorToken='OverlayColorTextBase'>
        Scan the QR code to view this bracket on your phone!
      </NxTypography>
    </Box>
  )
}
