import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useGetMyIdentityQuery } from '@plvs/live-fe/graphql'
import { Path } from '../paths'

export const AuthRoute: React.FC = () => {
  const { data, loading } = useGetMyIdentityQuery()
  const isAllowed = !!data?.me?.id
  if (loading) {
    return <></>
  }

  return isAllowed ? <Outlet /> : <Navigate replace to={Path.Login} />
}
