import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useBreakpointSm } from '@plvs/live-fe/utils/layoutUtils'
import { CreateCSSProperties } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  arrow: {
    position: 'absolute',
    top: theme.spacing(4),
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    '& svg': {
      position: 'relative',
    },
    border: '1px solid',
  },
  arrowEnabled: ({
    arrowColor,
  }: {
    arrowColor: string
  }): CreateCSSProperties => ({
    backgroundColor: arrowColor,
    color: theme.palette.OverlayColorTextBase,
    borderColor: arrowColor,
    cursor: 'pointer',
  }),
  arrowDisabled: {
    backgroundColor: theme.palette.OverlayColorBackgroundAlt3,
    color: theme.palette.ColorTextDisabled,
    borderColor: theme.palette.OverlayBorderMedium,
    cursor: 'not-allowed',
  },
  leftArrow: {
    left: theme.spacing(3),
    '& svg': {
      right: '8px',
      top: '-2px',
    },
  },
  rightArrow: {
    right: theme.spacing(3),
    '& svg': {
      left: '-6px',
      top: '-2px',
    },
  },
}))

const Arrow: React.FC<{
  className?: string
  onClick?(): void
  prev?: boolean
}> = ({ className, onClick, prev }) => (
  <button
    aria-label={prev ? 'prev' : 'next'}
    className={className}
    onClick={onClick}
    type="button"
  >
    {prev ? <ChevronLeft /> : <ChevronRight />}
  </button>
)

export const Draggable = ({
  scrollDistance = 415,
  children,
  arrowColor,
  useArrows = true,
}): React.ReactElement => {
  const isMobile = useBreakpointSm()
  const hideArrows = !useArrows || isMobile
  const classes = useStyles({ arrowColor })

  const ourRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>

  const [isMouseDown, setIsMouseDown] = useState(false)
  const [leftDisabled, setLeftDisabled] = useState(true)
  const [rightDisabled, setRightDisabled] = useState(true)
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  })

  const checkDisabled = (): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[hideArrows ? 0 : 2] as HTMLElement
    const maxScrollLeft = slider.scrollWidth - slider.clientWidth
    setLeftDisabled(slider.scrollLeft === 0)
    setRightDisabled(slider.scrollLeft === maxScrollLeft)
  }

  useEffect(() => {
    checkDisabled()
  }, [ourRef])

  const handleDragStart = (e): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[hideArrows ? 0 : 2] as HTMLElement
    const startX = e.pageX - slider.offsetLeft
    const startY = e.pageY - slider.offsetTop
    const { scrollLeft } = slider
    const { scrollTop } = slider
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true)
    document.body.style.cursor = 'grabbing'
  }

  const handleDragEnd = (): void => {
    setIsMouseDown(false)
    if (!ourRef.current) return
    document.body.style.cursor = 'default'
    checkDisabled()
  }

  const handleDrag = (e): void => {
    if (!isMouseDown || !ourRef.current) return
    e.preventDefault()
    const slider = ourRef.current.children[hideArrows ? 0 : 2] as HTMLElement
    const x = e.pageX - slider.offsetLeft
    const y = e.pageY - slider.offsetTop
    const walkX = (x - mouseCoords.current.startX) * 1.5
    const walkY = (y - mouseCoords.current.startY) * 1.5
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
    slider.scrollTop = mouseCoords.current.scrollTop - walkY
  }

  const clickMove = (left: boolean): void => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[hideArrows ? 0 : 2] as HTMLElement
    const scrollSize = left ? -scrollDistance : scrollDistance
    slider.scroll({
      left: slider.scrollLeft + scrollSize,
      behavior: 'smooth',
    })
    setTimeout(() => {
      checkDisabled()
    }, 200)
  }

  return (
    <div
      ref={ourRef}
      className={classes.container}
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      role="presentation"
    >
      {!hideArrows ? (
        <>
          <Arrow
            className={clsx(
              classes.leftArrow,
              classes.arrow,
              leftDisabled ? classes.arrowDisabled : classes.arrowEnabled
            )}
            onClick={(): void => clickMove(true)}
            prev
          />
          <Arrow
            className={clsx(
              classes.rightArrow,
              classes.arrow,
              rightDisabled ? classes.arrowDisabled : classes.arrowEnabled
            )}
            onClick={(): void => clickMove(false)}
          />
        </>
      ) : (
        <></>
      )}
      {children}
    </div>
  )
}
