import { v4 as uuidV4 } from 'uuid'
import * as Sentry from '@sentry/browser'

export type MutationEventTargetUnsubscribe = () => void
export type MutationEventHandler = (event: CustomEvent<string>) => void

/**
 * This event target receives an event every time a mutation is fired.
 * Components in our app can import `mutationEventTarget` and listen
 * for fired mutations.
 */
export class MutationEventTarget {
  private listeners: Record<string, MutationEventHandler> = {}

  subscribe(
    handler: (event: CustomEvent<string>) => void
  ): MutationEventTargetUnsubscribe {
    const lookup = uuidV4()
    this.listeners[lookup] = handler

    return (): void => {
      delete this.listeners[lookup]
    }
  }

  public emit(operationName: string): void {
    Object.entries(this.listeners).forEach(([key, handler]) => {
      try {
        handler(new CustomEvent<string>('mutation', { detail: operationName }))
      } catch (err: unknown) {
        Sentry.captureException(err)

        // After the first error capture, remove the handler so that we don't
        // continue to keep throwing on subsequent mutations.
        delete this.listeners[key]
      }
    })
  }
}

/**
 * Static instance of MutationEventTarget.
 */
export const mutationEventTarget = new MutationEventTarget()
