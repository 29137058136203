import React from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'
import { Smallcheck } from '@playvs-inc/nexus-icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: ({
    isWinner,
    themeColorCode,
  }: {
    isWinner: boolean
    themeColorCode: string
  }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: isWinner ? themeColorCode : theme.palette.ColorBackgroundAlt,
    padding: theme.spacing(1, 3, 1, 3),
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    minHeight: '54px',
  }),
  text: ({ isWinner }: { isWinner: boolean }): CreateCSSProperties => ({
    color: isWinner
      ? theme.palette.OverlayColorTextBase
      : theme.palette.ColorTextBase,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
  }),
  seed: {
    width: '20px',
    textAlign: 'center',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  avatar: {
    opacity: '1 !important',
  },
}))

export type BracketTeam = {
  id: string
  teamId: string
  seed: number
  name: string
  score: number
  winner: boolean
}

export interface BracketTeamProps {
  party: BracketTeam
  themeColorCode: string
}

export function EventBracketTeam({
  party,
  themeColorCode,
}: BracketTeamProps): React.ReactElement {
  const isWinner = party.winner

  const theme = useTheme()
  const classes = useStyles({
    isWinner,
    themeColorCode,
  })

  return (
    <Box className={classes.container}>
      <Box mr={2}>
        <NxTypography
          className={clsx(classes.seed, classes.text)}
          variant="body1"
        >
          {party.seed}
        </NxTypography>
      </Box>
      <Box className={classes.nameContainer} flex={1}>
        <NxTypography className={classes.text} variant="body2">
          {party.name}
        </NxTypography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row">
        {isWinner ? (
          <Smallcheck
            color={theme.palette.OverlayColorIconBase}
            height={24}
            width={24}
          />
        ) : (
          <></>
        )}
        <NxTypography className={classes.text} variant="body2">
          {party.score}
        </NxTypography>
      </Box>
    </Box>
  )
}
