// Config Mapping file, unit testing not needed here.
/* istanbul ignore file */
// If we have `window` then we are in a browser environment and should use that, otherwise use `process.env`.
const appropriateEnv =
  typeof window !== 'undefined' && window.env
    ? (window.env as Record<string, string>)
    : process.env

const getApiEnv = (apiUrl: string): NodeEnv => {
  if (apiUrl.includes('demo')) return 'demo'
  if (apiUrl.includes('localhost')) return 'development'
  if (apiUrl.includes('staging')) return 'staging'
  return 'production'
}

export type NodeEnv =
  | 'demo'
  | 'development'
  | 'staging'
  | 'production'
  | undefined

/**
 * Defines available LiveEnv type values.
 */
export interface LiveEnv {
  API_ENV: NodeEnv
  API_URL: string
  WS_URL: string
  GRAPHQL_WS_URL: string
  GRAPHQL_URI: string
  SENTRY_DSN: string
  COOKIE_DOMAIN: string
}

const LOCAL_API_URL = 'http://localhost:4001'
const API_URL = appropriateEnv.API_URL || LOCAL_API_URL

const LOCAL_WS_URL = 'ws://localhost:4001'
const WS_URL = appropriateEnv.WS_URL || LOCAL_WS_URL

/**
 * Define default values when not overriden by deployed ENV.
 */
export const liveEnv: LiveEnv = {
  // __________________________________________________________________________
  // API
  API_ENV: getApiEnv(API_URL),
  API_URL,
  WS_URL,
  GRAPHQL_WS_URL: `${WS_URL}/graphql`,
  GRAPHQL_URI: `${API_URL}/graphql`,

  // __________________________________________________________________________
  // THIRD-PARTY KEYS
  SENTRY_DSN: 'https://6affc91010eb4d8d901efb96564091af@sentry.io/1519550',

  // __________________________________________________________________________
  // OTHER
  COOKIE_DOMAIN: '',
}
