/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { useImageLoader } from '@plvs/live-fe/utils/imageUtils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PlayVSLiveLogoWhite from '@plvs/live-fe/assets/PlayVSLiveLogoWhite.svg'
import PatternBannerOrange from '@plvs/live-fe/assets/PatternBannerOrange.jpg'
import {
  NxButton,
  NxIconButton,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { UserOutlined } from '@playvs-inc/nexus-icons'
import clsx from 'clsx'
import { Path } from '@plvs/live-fe/pages/paths'
import { InMemoryCache } from '@apollo/client'
import { useLogout } from '@plvs/live-fe/utils/authUtils'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${PatternBannerOrange})`,
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  blackButton: {
    backgroundColor: theme.palette.OverlayColorOpBlack3,
    '&:hover': {
      backgroundColor: 'rgba(50, 50, 50)',
    },
  },
  iconButton: {
    width: '40px',
  },
  logo: {
    cursor: 'pointer',
  },
}))

export const AdminBanner: React.FC = () => {
  const cache = new InMemoryCache()
  const navigate = useNavigate()
  const logout = useLogout(cache)

  const classes = useStyles()
  const { isLoaded: bannerIsLoaded, imageSrc: bannerImageSrc } = useImageLoader(
    {
      src: PlayVSLiveLogoWhite,
    }
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleLogout = (): void => {
    logout()
    navigate(Path.Login)
  }

  return (
    <Box className={classes.container} py={2}>
      <Box display="flex">
        {bannerIsLoaded && bannerImageSrc && (
          <img
            alt="Live Banner"
            className={classes.logo}
            onClick={(): void => navigate(Path.Events)}
            src={bannerImageSrc}
          />
        )}
      </Box>
      <Box display="flex" position="absolute" right={32} top={32}>
        <Box mr={2}>
          <NxButton
            className={classes.blackButton}
            label="Create Event +"
            onClick={(): void => {
              navigate(Path.EventCreate)
            }}
            variant="primary"
          />
        </Box>
        <Box>
          <NxIconButton
            className={clsx(classes.blackButton, classes.iconButton)}
            icon={<UserOutlined />}
            onClick={handleClick}
            variant="primary"
          />
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            getContentAnchorEl={null}
            onClose={handleClose}
            open={open}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem onClick={handleClose}>
              <NxTypography
                onClick={(): void => navigate(Path.Events)}
                variant="body3"
              >
                Dashboard
              </NxTypography>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NxTypography onClick={handleLogout} variant="body3">
                Logout
              </NxTypography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}
