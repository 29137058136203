import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AccountNumber: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Byte: { input: any; output: any; }
  CountryCode: { input: any; output: any; }
  Cuid: { input: any; output: any; }
  Currency: { input: any; output: any; }
  DID: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  DateTimeISO: { input: any; output: any; }
  DeweyDecimal: { input: any; output: any; }
  Duration: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  GUID: { input: any; output: any; }
  HSL: { input: any; output: any; }
  HSLA: { input: any; output: any; }
  HexColorCode: { input: any; output: any; }
  Hexadecimal: { input: any; output: any; }
  IBAN: { input: any; output: any; }
  IP: { input: any; output: any; }
  IPCPatent: { input: any; output: any; }
  IPv4: { input: any; output: any; }
  IPv6: { input: any; output: any; }
  ISBN: { input: any; output: any; }
  ISO8601Duration: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWT: { input: any; output: any; }
  LCCSubclass: { input: any; output: any; }
  Latitude: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  LocalEndTime: { input: any; output: any; }
  LocalTime: { input: any; output: any; }
  Locale: { input: any; output: any; }
  Long: { input: any; output: any; }
  Longitude: { input: any; output: any; }
  MAC: { input: any; output: any; }
  NegativeFloat: { input: any; output: any; }
  NegativeInt: { input: any; output: any; }
  NonEmptyString: { input: any; output: any; }
  NonNegativeFloat: { input: any; output: any; }
  NonNegativeInt: { input: any; output: any; }
  NonPositiveFloat: { input: any; output: any; }
  NonPositiveInt: { input: any; output: any; }
  ObjectID: { input: string; output: string; }
  PhoneNumber: { input: any; output: any; }
  Port: { input: any; output: any; }
  PositiveFloat: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
  PostalCode: { input: any; output: any; }
  RGB: { input: any; output: any; }
  RGBA: { input: any; output: any; }
  RoutingNumber: { input: any; output: any; }
  SESSN: { input: any; output: any; }
  SafeInt: { input: any; output: any; }
  SemVer: { input: any; output: any; }
  Time: { input: any; output: any; }
  TimeZone: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
  URL: { input: any; output: any; }
  USCurrency: { input: any; output: any; }
  UUID: { input: string; output: string; }
  UnsignedFloat: { input: any; output: any; }
  UnsignedInt: { input: any; output: any; }
  Upload: { input: any; output: any; }
  UtcOffset: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type Bracket = {
  __typename?: 'Bracket';
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  results: Array<BracketMatch>;
  size: Scalars['Int']['output'];
  type: BracketType;
};

export type BracketMatch = {
  __typename?: 'BracketMatch';
  bracketId: Scalars['String']['output'];
  bracketMatchTeams: Array<BracketMatchTeam>;
  id: Scalars['String']['output'];
  matchId: Scalars['String']['output'];
  nextLooserMatchId: Maybe<Scalars['String']['output']>;
  nextMatchId: Maybe<Scalars['String']['output']>;
  ordinalNumber: Scalars['Int']['output'];
  roundNumber: Scalars['Int']['output'];
  status: BracketMatchStatus;
};

export enum BracketMatchStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Pending = 'pending',
  Unknown = 'unknown'
}

export type BracketMatchTeam = {
  __typename?: 'BracketMatchTeam';
  bracketMatchId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  matchScore: Maybe<Scalars['Int']['output']>;
  matchTeamOutcome: Maybe<Scalars['String']['output']>;
  matchWinner: Maybe<Scalars['Boolean']['output']>;
  teamId: Maybe<Scalars['String']['output']>;
};

export enum BracketType {
  DoubleElimination = 'doubleElimination',
  SingleElimination = 'singleElimination'
}

export type CompleteMatchInput = {
  id: Scalars['UUID']['input'];
  matchTeams: Array<UpdateBracketMatchTeamInput>;
};

export type CreateBracketInput = {
  eventId: Scalars['UUID']['input'];
  size: Scalars['Int']['input'];
  type: BracketType;
};

export type CreateEventInput = {
  bannerUrl?: InputMaybe<Scalars['String']['input']>;
  bracketBackgroundUrl?: InputMaybe<Scalars['String']['input']>;
  bracketType?: InputMaybe<BracketType>;
  defaultBracketSize: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  esport: Scalars['String']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  resultSubmissionPassword?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<EventStatus>;
  themeColorCode?: InputMaybe<Scalars['HexColorCode']['input']>;
};

export type CreateTeamInput = {
  eventId: Scalars['String']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ordinalNumber: Scalars['Int']['input'];
  seed?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type Event = {
  __typename?: 'Event';
  bannerUrl: Maybe<Scalars['String']['output']>;
  bracketBackgroundUrl: Maybe<Scalars['String']['output']>;
  brackets: Maybe<Array<Bracket>>;
  defaultBracketSize: Scalars['Int']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  esport: Scalars['String']['output'];
  hidden: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  seededTeamsCount: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<EventStatus>;
  teams: Maybe<Array<Team>>;
  themeColorCode: Maybe<Scalars['HexColorCode']['output']>;
};

export type EventAuthorized = {
  __typename?: 'EventAuthorized';
  bannerUrl: Maybe<Scalars['String']['output']>;
  bracketBackgroundUrl: Maybe<Scalars['String']['output']>;
  brackets: Maybe<Array<Bracket>>;
  defaultBracketSize: Scalars['Int']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  esport: Scalars['String']['output'];
  hidden: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  resultSubmissionPassword: Maybe<Scalars['String']['output']>;
  seededTeamsCount: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<EventStatus>;
  teams: Maybe<Array<Team>>;
  themeColorCode: Maybe<Scalars['HexColorCode']['output']>;
};

export enum EventStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

export type EventsPaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type File = {
  __typename?: 'File';
  key: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GenerateUploadUrlInput = {
  contentType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GenerateUploadUrlPayload = {
  __typename?: 'GenerateUploadUrlPayload';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['String']['output'];
  refreshToken: Maybe<Scalars['String']['output']>;
  tokenType: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  completeBracketMatch: BracketMatch;
  completeBracketMatchWithPassword: BracketMatch;
  createEvent: Event;
  createEventBracket: Bracket;
  createTeam: Team;
  createUser: User;
  deleteTeam: Scalars['Boolean']['output'];
  generateUploadUrl: GenerateUploadUrlPayload;
  login: LoginResponse;
  previewEventBracket: Bracket;
  recreateEventBracket: Bracket;
  resetBracketMatch: BracketMatch;
  resetBracketMatchWithPassword: BracketMatch;
  setEventTeams: Array<Team>;
  updateEvent: Event;
  updateEventStatus: Event;
  uploadFile: File;
};


export type MutationCompleteBracketMatchArgs = {
  input: CompleteMatchInput;
};


export type MutationCompleteBracketMatchWithPasswordArgs = {
  input: CompleteMatchInput;
  password: Scalars['String']['input'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventBracketArgs = {
  input: CreateBracketInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteTeamArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationGenerateUploadUrlArgs = {
  input: GenerateUploadUrlInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPreviewEventBracketArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRecreateEventBracketArgs = {
  input: CreateBracketInput;
};


export type MutationResetBracketMatchArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationResetBracketMatchWithPasswordArgs = {
  id: Scalars['UUID']['input'];
  password: Scalars['String']['input'];
};


export type MutationSetEventTeamsArgs = {
  input: Array<CreateTeamInput>;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateEventStatusArgs = {
  input: UpdateEventStatus;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};

export type PagedEvents = {
  __typename?: 'PagedEvents';
  events: Array<EventAuthorized>;
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  bracket: Bracket;
  event: EventAuthorized;
  eventBySlug: Event;
  eventBySlugWithPassword: Event;
  events: PagedEvents;
  me: User;
  team: Team;
  teams: Array<Team>;
};


export type QueryBracketArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryEventBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryEventBySlugWithPasswordArgs = {
  password: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  input: EventsPaginationInput;
};


export type QueryTeamArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTeamsArgs = {
  eventId: Scalars['UUID']['input'];
};

export type Team = {
  __typename?: 'Team';
  eventId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ordinalNumber: Scalars['Int']['output'];
  seed: Maybe<Scalars['Int']['output']>;
};


export type TeamEventIdArgs = {
  teamId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateBracketMatchTeamInput = {
  id: Scalars['UUID']['input'];
  matchScore?: InputMaybe<Scalars['Int']['input']>;
  matchTeamOutcome?: InputMaybe<Scalars['String']['input']>;
  matchWinner: Scalars['Boolean']['input'];
};

export type UpdateEventInput = {
  bracketType?: InputMaybe<BracketType>;
  event: CreateEventInput;
  id: Scalars['UUID']['input'];
};

export type UpdateEventStatus = {
  id: Scalars['UUID']['input'];
  status: EventStatus;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type PreviewEventBracketMutationVariables = Exact<{
  previewEventBracketId: Scalars['UUID']['input'];
}>;


export type PreviewEventBracketMutation = { __typename?: 'Mutation', previewEventBracket: { __typename?: 'Bracket', id: string } };

export type CompleteBracketMatchMutationVariables = Exact<{
  input: CompleteMatchInput;
}>;


export type CompleteBracketMatchMutation = { __typename?: 'Mutation', completeBracketMatch: { __typename?: 'BracketMatch', id: string } };

export type ResetBracketMatchMutationVariables = Exact<{
  matchId: Scalars['UUID']['input'];
}>;


export type ResetBracketMatchMutation = { __typename?: 'Mutation', resetBracketMatch: { __typename?: 'BracketMatch', id: string } };

export type CompleteBracketMatchWithPasswordMutationVariables = Exact<{
  input: CompleteMatchInput;
  password: Scalars['String']['input'];
}>;


export type CompleteBracketMatchWithPasswordMutation = { __typename?: 'Mutation', completeBracketMatchWithPassword: { __typename?: 'BracketMatch', id: string } };

export type ResetBracketMatchWithPasswordMutationVariables = Exact<{
  matchId: Scalars['UUID']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetBracketMatchWithPasswordMutation = { __typename?: 'Mutation', resetBracketMatchWithPassword: { __typename?: 'BracketMatch', id: string } };

export type EventQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type EventQuery = { __typename?: 'Query', event: { __typename?: 'EventAuthorized', id: string, name: string, slug: string, esport: string, defaultBracketSize: number, resultSubmissionPassword: string | null, bannerUrl: string | null, bracketBackgroundUrl: string | null, themeColorCode: any | null, status: EventStatus | null, teams: Array<{ __typename?: 'Team', id: string, name: string, seed: number | null }> | null, brackets: Array<{ __typename?: 'Bracket', id: string, size: number, type: BracketType, results: Array<{ __typename?: 'BracketMatch', id: string, roundNumber: number, ordinalNumber: number, matchId: string, nextMatchId: string | null, status: BracketMatchStatus, bracketMatchTeams: Array<{ __typename?: 'BracketMatchTeam', id: string, bracketMatchId: string, teamId: string | null, matchScore: number | null, matchWinner: boolean | null, matchTeamOutcome: string | null }> }> }> | null } };

export type EventBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type EventBySlugQuery = { __typename?: 'Query', eventBySlug: { __typename?: 'Event', id: string, name: string, slug: string, esport: string, defaultBracketSize: number, bannerUrl: string | null, bracketBackgroundUrl: string | null, themeColorCode: any | null, status: EventStatus | null, teams: Array<{ __typename?: 'Team', id: string, name: string, seed: number | null }> | null, brackets: Array<{ __typename?: 'Bracket', id: string, size: number, type: BracketType, results: Array<{ __typename?: 'BracketMatch', id: string, roundNumber: number, ordinalNumber: number, matchId: string, nextMatchId: string | null, status: BracketMatchStatus, bracketMatchTeams: Array<{ __typename?: 'BracketMatchTeam', id: string, bracketMatchId: string, teamId: string | null, matchScore: number | null, matchWinner: boolean | null, matchTeamOutcome: string | null }> }> }> | null } };

export type EventBySlugWithPasswordQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type EventBySlugWithPasswordQuery = { __typename?: 'Query', eventBySlugWithPassword: { __typename?: 'Event', id: string, name: string, slug: string, esport: string, defaultBracketSize: number, bannerUrl: string | null, bracketBackgroundUrl: string | null, themeColorCode: any | null, status: EventStatus | null, teams: Array<{ __typename?: 'Team', id: string, name: string, seed: number | null }> | null, brackets: Array<{ __typename?: 'Bracket', id: string, size: number, type: BracketType, results: Array<{ __typename?: 'BracketMatch', id: string, roundNumber: number, ordinalNumber: number, matchId: string, nextMatchId: string | null, status: BracketMatchStatus, bracketMatchTeams: Array<{ __typename?: 'BracketMatchTeam', id: string, bracketMatchId: string, teamId: string | null, matchScore: number | null, matchWinner: boolean | null, matchTeamOutcome: string | null }> }> }> | null } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string } };

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string } };

export type SetEventTeamsMutationVariables = Exact<{
  input: Array<CreateTeamInput> | CreateTeamInput;
}>;


export type SetEventTeamsMutation = { __typename?: 'Mutation', setEventTeams: Array<{ __typename?: 'Team', id: string }> };

export type EventsQueryVariables = Exact<{
  input: EventsPaginationInput;
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'PagedEvents', total: number, events: Array<{ __typename?: 'EventAuthorized', id: string, name: string, esport: string, status: EventStatus | null, slug: string, defaultBracketSize: number, seededTeamsCount: number | null }> } };

export type UpdateEventStatusMutationVariables = Exact<{
  input: UpdateEventStatus;
}>;


export type UpdateEventStatusMutation = { __typename?: 'Mutation', updateEventStatus: { __typename?: 'Event', id: string } };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string } };

export type GetMyIdentityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyIdentityQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string | null, email: string } };

export type GenerateUploadUrlMutationVariables = Exact<{
  input: GenerateUploadUrlInput;
}>;


export type GenerateUploadUrlMutation = { __typename?: 'Mutation', generateUploadUrl: { __typename?: 'GenerateUploadUrlPayload', url: string } };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'File', url: string } };


export const PreviewEventBracketDocument = gql`
    mutation previewEventBracket($previewEventBracketId: UUID!) {
  previewEventBracket(id: $previewEventBracketId) {
    id
  }
}
    `;
export type PreviewEventBracketMutationFn = Apollo.MutationFunction<PreviewEventBracketMutation, PreviewEventBracketMutationVariables>;

/**
 * __usePreviewEventBracketMutation__
 *
 * To run a mutation, you first call `usePreviewEventBracketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewEventBracketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewEventBracketMutation, { data, loading, error }] = usePreviewEventBracketMutation({
 *   variables: {
 *      previewEventBracketId: // value for 'previewEventBracketId'
 *   },
 * });
 */
export function usePreviewEventBracketMutation(baseOptions?: Apollo.MutationHookOptions<PreviewEventBracketMutation, PreviewEventBracketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewEventBracketMutation, PreviewEventBracketMutationVariables>(PreviewEventBracketDocument, options);
      }
export type PreviewEventBracketMutationHookResult = ReturnType<typeof usePreviewEventBracketMutation>;
export type PreviewEventBracketMutationResult = Apollo.MutationResult<PreviewEventBracketMutation>;
export type PreviewEventBracketMutationOptions = Apollo.BaseMutationOptions<PreviewEventBracketMutation, PreviewEventBracketMutationVariables>;
export const CompleteBracketMatchDocument = gql`
    mutation completeBracketMatch($input: CompleteMatchInput!) {
  completeBracketMatch(input: $input) {
    id
  }
}
    `;
export type CompleteBracketMatchMutationFn = Apollo.MutationFunction<CompleteBracketMatchMutation, CompleteBracketMatchMutationVariables>;

/**
 * __useCompleteBracketMatchMutation__
 *
 * To run a mutation, you first call `useCompleteBracketMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteBracketMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeBracketMatchMutation, { data, loading, error }] = useCompleteBracketMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteBracketMatchMutation(baseOptions?: Apollo.MutationHookOptions<CompleteBracketMatchMutation, CompleteBracketMatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteBracketMatchMutation, CompleteBracketMatchMutationVariables>(CompleteBracketMatchDocument, options);
      }
export type CompleteBracketMatchMutationHookResult = ReturnType<typeof useCompleteBracketMatchMutation>;
export type CompleteBracketMatchMutationResult = Apollo.MutationResult<CompleteBracketMatchMutation>;
export type CompleteBracketMatchMutationOptions = Apollo.BaseMutationOptions<CompleteBracketMatchMutation, CompleteBracketMatchMutationVariables>;
export const ResetBracketMatchDocument = gql`
    mutation resetBracketMatch($matchId: UUID!) {
  resetBracketMatch(id: $matchId) {
    id
  }
}
    `;
export type ResetBracketMatchMutationFn = Apollo.MutationFunction<ResetBracketMatchMutation, ResetBracketMatchMutationVariables>;

/**
 * __useResetBracketMatchMutation__
 *
 * To run a mutation, you first call `useResetBracketMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBracketMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBracketMatchMutation, { data, loading, error }] = useResetBracketMatchMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useResetBracketMatchMutation(baseOptions?: Apollo.MutationHookOptions<ResetBracketMatchMutation, ResetBracketMatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBracketMatchMutation, ResetBracketMatchMutationVariables>(ResetBracketMatchDocument, options);
      }
export type ResetBracketMatchMutationHookResult = ReturnType<typeof useResetBracketMatchMutation>;
export type ResetBracketMatchMutationResult = Apollo.MutationResult<ResetBracketMatchMutation>;
export type ResetBracketMatchMutationOptions = Apollo.BaseMutationOptions<ResetBracketMatchMutation, ResetBracketMatchMutationVariables>;
export const CompleteBracketMatchWithPasswordDocument = gql`
    mutation completeBracketMatchWithPassword($input: CompleteMatchInput!, $password: String!) {
  completeBracketMatchWithPassword(input: $input, password: $password) {
    id
  }
}
    `;
export type CompleteBracketMatchWithPasswordMutationFn = Apollo.MutationFunction<CompleteBracketMatchWithPasswordMutation, CompleteBracketMatchWithPasswordMutationVariables>;

/**
 * __useCompleteBracketMatchWithPasswordMutation__
 *
 * To run a mutation, you first call `useCompleteBracketMatchWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteBracketMatchWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeBracketMatchWithPasswordMutation, { data, loading, error }] = useCompleteBracketMatchWithPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCompleteBracketMatchWithPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CompleteBracketMatchWithPasswordMutation, CompleteBracketMatchWithPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteBracketMatchWithPasswordMutation, CompleteBracketMatchWithPasswordMutationVariables>(CompleteBracketMatchWithPasswordDocument, options);
      }
export type CompleteBracketMatchWithPasswordMutationHookResult = ReturnType<typeof useCompleteBracketMatchWithPasswordMutation>;
export type CompleteBracketMatchWithPasswordMutationResult = Apollo.MutationResult<CompleteBracketMatchWithPasswordMutation>;
export type CompleteBracketMatchWithPasswordMutationOptions = Apollo.BaseMutationOptions<CompleteBracketMatchWithPasswordMutation, CompleteBracketMatchWithPasswordMutationVariables>;
export const ResetBracketMatchWithPasswordDocument = gql`
    mutation resetBracketMatchWithPassword($matchId: UUID!, $password: String!) {
  resetBracketMatchWithPassword(id: $matchId, password: $password) {
    id
  }
}
    `;
export type ResetBracketMatchWithPasswordMutationFn = Apollo.MutationFunction<ResetBracketMatchWithPasswordMutation, ResetBracketMatchWithPasswordMutationVariables>;

/**
 * __useResetBracketMatchWithPasswordMutation__
 *
 * To run a mutation, you first call `useResetBracketMatchWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBracketMatchWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBracketMatchWithPasswordMutation, { data, loading, error }] = useResetBracketMatchWithPasswordMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetBracketMatchWithPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetBracketMatchWithPasswordMutation, ResetBracketMatchWithPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBracketMatchWithPasswordMutation, ResetBracketMatchWithPasswordMutationVariables>(ResetBracketMatchWithPasswordDocument, options);
      }
export type ResetBracketMatchWithPasswordMutationHookResult = ReturnType<typeof useResetBracketMatchWithPasswordMutation>;
export type ResetBracketMatchWithPasswordMutationResult = Apollo.MutationResult<ResetBracketMatchWithPasswordMutation>;
export type ResetBracketMatchWithPasswordMutationOptions = Apollo.BaseMutationOptions<ResetBracketMatchWithPasswordMutation, ResetBracketMatchWithPasswordMutationVariables>;
export const EventDocument = gql`
    query event($id: UUID!) {
  event(id: $id) {
    id
    name
    slug
    esport
    defaultBracketSize
    resultSubmissionPassword
    bannerUrl
    bracketBackgroundUrl
    themeColorCode
    status
    teams {
      id
      name
      seed
    }
    brackets {
      id
      size
      type
      results {
        id
        roundNumber
        ordinalNumber
        matchId
        nextMatchId
        status
        bracketMatchTeams {
          id
          bracketMatchId
          teamId
          matchScore
          matchWinner
          matchTeamOutcome
        }
      }
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export function refetchEventQuery(variables: EventQueryVariables) {
      return { query: EventDocument, variables: variables }
    }
export const EventBySlugDocument = gql`
    query eventBySlug($slug: String!) {
  eventBySlug(slug: $slug) {
    id
    name
    slug
    esport
    defaultBracketSize
    bannerUrl
    bracketBackgroundUrl
    themeColorCode
    status
    teams {
      id
      name
      seed
    }
    brackets {
      id
      size
      type
      results {
        id
        roundNumber
        ordinalNumber
        matchId
        nextMatchId
        status
        bracketMatchTeams {
          id
          bracketMatchId
          teamId
          matchScore
          matchWinner
          matchTeamOutcome
        }
      }
    }
  }
}
    `;

/**
 * __useEventBySlugQuery__
 *
 * To run a query within a React component, call `useEventBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventBySlugQuery(baseOptions: Apollo.QueryHookOptions<EventBySlugQuery, EventBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, options);
      }
export function useEventBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventBySlugQuery, EventBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, options);
        }
export type EventBySlugQueryHookResult = ReturnType<typeof useEventBySlugQuery>;
export type EventBySlugLazyQueryHookResult = ReturnType<typeof useEventBySlugLazyQuery>;
export type EventBySlugQueryResult = Apollo.QueryResult<EventBySlugQuery, EventBySlugQueryVariables>;
export function refetchEventBySlugQuery(variables: EventBySlugQueryVariables) {
      return { query: EventBySlugDocument, variables: variables }
    }
export const EventBySlugWithPasswordDocument = gql`
    query eventBySlugWithPassword($slug: String!, $password: String!) {
  eventBySlugWithPassword(slug: $slug, password: $password) {
    id
    name
    slug
    esport
    defaultBracketSize
    bannerUrl
    bracketBackgroundUrl
    themeColorCode
    status
    teams {
      id
      name
      seed
    }
    brackets {
      id
      size
      type
      results {
        id
        roundNumber
        ordinalNumber
        matchId
        nextMatchId
        status
        bracketMatchTeams {
          id
          bracketMatchId
          teamId
          matchScore
          matchWinner
          matchTeamOutcome
        }
      }
    }
  }
}
    `;

/**
 * __useEventBySlugWithPasswordQuery__
 *
 * To run a query within a React component, call `useEventBySlugWithPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventBySlugWithPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventBySlugWithPasswordQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEventBySlugWithPasswordQuery(baseOptions: Apollo.QueryHookOptions<EventBySlugWithPasswordQuery, EventBySlugWithPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventBySlugWithPasswordQuery, EventBySlugWithPasswordQueryVariables>(EventBySlugWithPasswordDocument, options);
      }
export function useEventBySlugWithPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventBySlugWithPasswordQuery, EventBySlugWithPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventBySlugWithPasswordQuery, EventBySlugWithPasswordQueryVariables>(EventBySlugWithPasswordDocument, options);
        }
export type EventBySlugWithPasswordQueryHookResult = ReturnType<typeof useEventBySlugWithPasswordQuery>;
export type EventBySlugWithPasswordLazyQueryHookResult = ReturnType<typeof useEventBySlugWithPasswordLazyQuery>;
export type EventBySlugWithPasswordQueryResult = Apollo.QueryResult<EventBySlugWithPasswordQuery, EventBySlugWithPasswordQueryVariables>;
export function refetchEventBySlugWithPasswordQuery(variables: EventBySlugWithPasswordQueryVariables) {
      return { query: EventBySlugWithPasswordDocument, variables: variables }
    }
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    id
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const SetEventTeamsDocument = gql`
    mutation setEventTeams($input: [CreateTeamInput!]!) {
  setEventTeams(input: $input) {
    id
  }
}
    `;
export type SetEventTeamsMutationFn = Apollo.MutationFunction<SetEventTeamsMutation, SetEventTeamsMutationVariables>;

/**
 * __useSetEventTeamsMutation__
 *
 * To run a mutation, you first call `useSetEventTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventTeamsMutation, { data, loading, error }] = useSetEventTeamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEventTeamsMutation(baseOptions?: Apollo.MutationHookOptions<SetEventTeamsMutation, SetEventTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEventTeamsMutation, SetEventTeamsMutationVariables>(SetEventTeamsDocument, options);
      }
export type SetEventTeamsMutationHookResult = ReturnType<typeof useSetEventTeamsMutation>;
export type SetEventTeamsMutationResult = Apollo.MutationResult<SetEventTeamsMutation>;
export type SetEventTeamsMutationOptions = Apollo.BaseMutationOptions<SetEventTeamsMutation, SetEventTeamsMutationVariables>;
export const EventsDocument = gql`
    query events($input: EventsPaginationInput!) {
  events(input: $input) {
    events {
      id
      name
      esport
      status
      slug
      defaultBracketSize
      seededTeamsCount
    }
    total
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export function refetchEventsQuery(variables: EventsQueryVariables) {
      return { query: EventsDocument, variables: variables }
    }
export const UpdateEventStatusDocument = gql`
    mutation updateEventStatus($input: UpdateEventStatus!) {
  updateEventStatus(input: $input) {
    id
  }
}
    `;
export type UpdateEventStatusMutationFn = Apollo.MutationFunction<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>;

/**
 * __useUpdateEventStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventStatusMutation, { data, loading, error }] = useUpdateEventStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>(UpdateEventStatusDocument, options);
      }
export type UpdateEventStatusMutationHookResult = ReturnType<typeof useUpdateEventStatusMutation>;
export type UpdateEventStatusMutationResult = Apollo.MutationResult<UpdateEventStatusMutation>;
export type UpdateEventStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEventStatusMutation, UpdateEventStatusMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetMyIdentityDocument = gql`
    query getMyIdentity {
  me {
    id
    name
    email
  }
}
    `;

/**
 * __useGetMyIdentityQuery__
 *
 * To run a query within a React component, call `useGetMyIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyIdentityQuery(baseOptions?: Apollo.QueryHookOptions<GetMyIdentityQuery, GetMyIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyIdentityQuery, GetMyIdentityQueryVariables>(GetMyIdentityDocument, options);
      }
export function useGetMyIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyIdentityQuery, GetMyIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyIdentityQuery, GetMyIdentityQueryVariables>(GetMyIdentityDocument, options);
        }
export type GetMyIdentityQueryHookResult = ReturnType<typeof useGetMyIdentityQuery>;
export type GetMyIdentityLazyQueryHookResult = ReturnType<typeof useGetMyIdentityLazyQuery>;
export type GetMyIdentityQueryResult = Apollo.QueryResult<GetMyIdentityQuery, GetMyIdentityQueryVariables>;
export function refetchGetMyIdentityQuery(variables?: GetMyIdentityQueryVariables) {
      return { query: GetMyIdentityDocument, variables: variables }
    }
export const GenerateUploadUrlDocument = gql`
    mutation generateUploadUrl($input: GenerateUploadUrlInput!) {
  generateUploadUrl(input: $input) {
    url
  }
}
    `;
export type GenerateUploadUrlMutationFn = Apollo.MutationFunction<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>;

/**
 * __useGenerateUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadUrlMutation, { data, loading, error }] = useGenerateUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>(GenerateUploadUrlDocument, options);
      }
export type GenerateUploadUrlMutationHookResult = ReturnType<typeof useGenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationResult = Apollo.MutationResult<GenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  uploadFile(file: $file) {
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;