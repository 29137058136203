import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { liveEnv } from '@plvs/live-fe/env'
import { possibleTypes } from '@plvs/live-fe/graphql'
import { typePolicies } from './typePolicies'
import { errorLink } from './middleware/errorLink'
import { forceLogoutLink } from './middleware/forceLogoutLink'
import { mutationWatcherLink } from './middleware/mutationWatcherLink'
import { splitLink } from './middleware/splitWsLink'

export const initApolloClient = ({
  jwt,
  name,
  version,
}: {
  jwt: string
  name?: string
  version?: string
}): ApolloClient<Record<string, unknown>> => {
  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies,
  })

  const client = new ApolloClient({
    cache,
    connectToDevTools: liveEnv.API_ENV !== 'production',
    link: ApolloLink.from([
      forceLogoutLink(cache),
      mutationWatcherLink,
      errorLink(),
      createPersistedQueryLink() as unknown as ApolloLink,
      splitLink(jwt),
    ]),
    resolvers: {},
    ssrMode: false,
    name: name ?? 'undefined-client',
    version: version ?? 'undefined-version',
  })

  return client
}
