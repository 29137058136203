import React from 'react'
import * as Sentry from '@sentry/browser'
import { CookiesProvider, useCookies } from 'react-cookie'
import { ApolloProvider } from '@apollo/client'
import { render } from 'react-dom'
import { SentryProvider, initSentry } from './analytics/sentry/initSentry'
import App from './App'
import { initApolloClient } from './init/apollo'

export enum Cookie {
  Token = 'token',
}

initSentry(Sentry)

const Client = (): React.ReactElement => {
  const [cookies] = useCookies([Cookie.Token])
  React.useEffect((): void => {
    const jssStyles = document.querySelector('#jss-server-side')
    jssStyles?.parentNode?.removeChild(jssStyles)
  }, [])

  return (
    <SentryProvider client={Sentry}>
      <CookiesProvider>
        <ApolloProvider
          client={initApolloClient({
            jwt: cookies[Cookie.Token],
            name: 'live-fe',
            version: '1.0',
          })}
        >
          <App />
        </ApolloProvider>
      </CookiesProvider>
    </SentryProvider>
  )
}

render(<Client />, document.getElementById('root'))
