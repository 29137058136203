import { Box } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'

export const Page: React.FC<{
  children: React.ReactNode
  title: string
}> = ({ children, title }) => {
  return (
    <Box>
      <Helmet>
        <title>{title}</title>
        <meta content="noindex" name="robots" />
      </Helmet>
      {children}
    </Box>
  )
}
