import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  EventBracketMatchResultModal,
  TeamInput,
} from './EventBracketMatchResultModal'
import { Dictionary } from 'lodash'
import { EventBracketMatch } from './EventBracketMatch'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'
import { BracketMatchStatus, BracketType } from '@plvs/live-fe/graphql'

const useStyles = makeStyles(() => ({
  horizontalLine: {
    border: `1px solid #555`,
    width: '20px',
    height: '1px'
  },
  verticalLine: {
    border: `1px solid #555`,
    width: '1px',
  },
}))
//DarkTheme/Background/DarkBorder
const height = 158

export interface BracketProps {
  matchesGrouped: Dictionary<any>
  themeColorCode: string
  bracketType: BracketType
  upper?: boolean
  editable?: boolean
  onSubmit?: (matchId: string, teams: TeamInput[]) => void
  onDelete?: (matchId: string) => void
}

export function EventBracket({
  matchesGrouped,
  themeColorCode,
  bracketType,
  upper,
  editable,
  onSubmit,
  onDelete,
}: BracketProps): React.ReactElement {

  const classes = useStyles()
  const maxMatches = matchesGrouped[0].length
  const numRounds = Object.keys(matchesGrouped).length
  const [open, setOpen] = useState<boolean>(false)
  const [selectedMatchId, setSelectedMatchId] = useState<string>('')
  const [selectedTopParty, setSelectedTopParty] = useState<TeamInput>()
  const [selectedBottomParty, setSelectedBottomParty] = useState<TeamInput>()

  function getRoundName(
    roundNumber: number
  ): string {
    if (bracketType === BracketType.DoubleElimination) {
      if (upper) {
        if (roundNumber === numRounds) {
          return 'Grand Finals'
        }
        if (roundNumber + 1 === numRounds) {
          return 'Finals'
        }
        if (roundNumber + 2 === numRounds) {
          return 'Upper Finals'
        }
        if (roundNumber + 3 === numRounds) {
          return 'Upper Semi-Finals'
        }
        else {
          return `Upper Round ${roundNumber}`
        }
      }
      else {
        if (roundNumber === numRounds) {
          return 'Lower Finals'
        }
        if (roundNumber + 1 === numRounds) {
          return 'Lower Semi-Finals'
        }
        else {
          return `Lower Round ${roundNumber}`
        }
      }
    }
    else {
      if (roundNumber === numRounds) {
        return 'Finals'
      }
      if (roundNumber + 1 === numRounds) {
        return 'Semi-Finals'
      }
    }
    return `Round ${roundNumber}`
  }

  function onClick(matchId: string, teams: TeamInput[]) {
    setSelectedMatchId(matchId)
    setSelectedTopParty(teams[0])
    setSelectedBottomParty(teams[1])
    setOpen(true)
  }

  return (
    <Box pt={8} px={5} display="flex">
      {
        Object.keys(matchesGrouped).map(round => {
          const roundNum = Number(round)
          const matchesForRound = matchesGrouped[roundNum]
          const numMatchesInRound = matchesForRound.length
          const colSize = maxMatches / numMatchesInRound
          const verticalPositionCalc = ((colSize - 1) / 2) * height
          const verticalPosition = verticalPositionCalc > 0 ? verticalPositionCalc : 0
          const isCompleted = matchesForRound.every(m => m.status === BracketMatchStatus.Complete)

          function calculateMarginTop(roundIndex: number): number {
            if (roundIndex === 0) {
              return 0
            }
            return verticalPosition * 2
          }
          if (roundNum + 1 === numRounds && upper) {
            const prevRound = matchesGrouped[roundNum - 1]
            if (!prevRound[0].nextMatchId) {
              return <></>
            }
          }

          return (
            <Box>
              <Box display="flex" alignItems="center" mb={6} flexDirection="column" height="80px">
                <Box mb={1}>
                  <NxTypography colorToken='OverlayColorTextBase' variant='h4'>
                    {getRoundName(roundNum + 1)}
                  </NxTypography>
                </Box>
                <Box mb={1}>
                  <NxTypography colorToken='OverlayColorTextBase' variant='overline'>
                    {numMatchesInRound * 2} Teams
                  </NxTypography>
                </Box>
                {isCompleted && <Pill size={'medium'} label={'Completed'} variant={'inactive'} />}
              </Box>
              <Box pt={`${verticalPosition}px`}>
                {
                  matchesForRound.map((m, index) => {
                    function renderHorizontalLine(): React.ReactNode {
                      const lineHeight = verticalPositionCalc > 0 ? verticalPositionCalc * 2 : height / 2
                      if (!matchesGrouped[roundNum + 1]) {
                        return <></>
                      }
                      else {
                        const numMatchesInNextRound = matchesGrouped[roundNum + 1].length
                        const down = index % 2 === 0 ? true : false
                        if (numMatchesInNextRound === numMatchesInRound) {
                          return <></>
                        }
                        else if (down) {
                          return (<Box className={classes.verticalLine} height={lineHeight + 2} mb={`${-1 * lineHeight}px`} />)
                        }
                        else {
                          return (<Box className={classes.verticalLine} height={lineHeight} mt={`${-1 * lineHeight + 2}px`} />)
                        }
                      }
                    }

                    return (
                      <Box display="flex" alignItems="center" marginTop={`${calculateMarginTop(index)}px`}>
                        {roundNum === 0 ? <></> : <Box className={classes.horizontalLine} />}
                        <EventBracketMatch
                          match={m}
                          topParty={m.participants[0]}
                          bottomParty={m.participants[1]}
                          themeColorCode={themeColorCode}
                          onClick={onClick}
                        />
                        {m.nextMatchId && matchesGrouped[roundNum + 1] ? <Box className={classes.horizontalLine} /> : <></>}
                        {renderHorizontalLine()}
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>
          )
        })
      }
      {editable && onDelete && onSubmit && selectedBottomParty && selectedTopParty && (
        <EventBracketMatchResultModal
          bottomTeam={selectedBottomParty}
          matchId={selectedMatchId}
          onDelete={onDelete}
          onSubmit={onSubmit}
          open={open}
          setOpen={setOpen}
          topTeam={selectedTopParty}
        />
      )}
    </Box>
  )
}
