import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxButton,
  NxTextArea,
  NxTypography,
} from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  formCard: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    padding: theme.spacing(3),
  },
}))

type EventTeamInput = {
  id: string
  name: string
  seed: number | null
}

interface EventTeamFormProps {
  eventTeams?: EventTeamInput[]
  onSubmit(input: { name: string; ordinalNumber: number }[]): void
  loading: boolean
}

export const EventTeamForm: React.FC<EventTeamFormProps> = ({
  eventTeams,
  onSubmit,
  loading,
}) => {
  const classes = useStyles()
  const [teams, setTeams] = useState<string>()

  const handleChange = (evnt: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setTeams(evnt.target.value)
  }

  const handleSubmit = async (): Promise<void> => {
    const teamsInput = teams?.split('\n').map((line, index) => {
      return { ordinalNumber: index + 1, name: line }
    })
    if (teamsInput) {
      onSubmit(teamsInput)
    }
  }

  return (
    <Box className={classes.formCard}>
      <Box mb={3}>
        <NxTypography variant="h3">Participants</NxTypography>
      </Box>
      <Box mb={3}>
        {eventTeams?.map((eventTeam) => {
          return (
            <NxTypography key={eventTeam.id}>
              {eventTeam.seed}. {eventTeam.name}
            </NxTypography>
          )
        })}
      </Box>
      <Box mb={3}>
        <NxTextArea
          fullWidth
          label="Add Participants, one per line."
          onChange={handleChange}
          rows={6}
        />
      </Box>
      <Box>
        <NxButton
          disabled={loading}
          fullWidth
          label="Set Participants"
          onClick={handleSubmit}
          variant="primary"
        />
      </Box>
    </Box>
  )
}
