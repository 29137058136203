import { ApolloCache, NormalizedCacheObject } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'universal-cookie'
import { Cookie } from '../client'
import { safeRemoveCookie } from './routingUtils'

export interface DecodedJwt {
  email: string
  userId: string
}

export interface JWTPayload {
  email: string
  sub: string
}

export const decodeJwt = (token: string): DecodedJwt => {
  try {
    const raw = jwtDecode<JWTPayload>(token) || null

    return {
      email: raw?.email ?? '',
      userId: raw?.sub ?? '',
    }
  } catch (err) {
    // Swallow the error
  }

  return {
    email: '',
    userId: '',
  }
}

const cookies = new Cookies()

export const useLogout = (
  cache: ApolloCache<NormalizedCacheObject>
): (() => Promise<void>) => {
  const handleLogout = async (): Promise<void> => {
    const token = cookies.get(Cookie.Token)
    if (token) {
      safeRemoveCookie((...args) => cookies.remove(...args), Cookie.Token)
      localStorage.clear()
      sessionStorage.clear()
      cache.reset()
      window.location.reload()
    }
  }
  return handleLogout
}
