import { Box, makeStyles } from '@material-ui/core'
import { AdminPage } from '@plvs/live-fe/components/layout/AdminPage'
import {
  BracketType,
  CreateEventInput,
  EventStatus,
  UpdateBracketMatchTeamInput,
  useCompleteBracketMatchMutation,
  useCreateEventMutation,
  useEventQuery,
  usePreviewEventBracketMutation,
  useResetBracketMatchMutation,
  useSetEventTeamsMutation,
  useUpdateEventMutation,
  useUpdateEventStatusMutation,
} from '@plvs/live-fe/graphql'
import React, { useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { EventForm } from '@plvs/live-fe/components/event/EventForm'
import {
  NxDropdownButton,
  NxTab,
  NxTabs,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { EventTeamForm } from '@plvs/live-fe/components/event/EventTeamForm'
import { EventBracketContainer } from '@plvs/live-fe/components/event/EventBracketContainer'
import { useClipboard } from '@plvs/live-fe/utils/useClipboard'
import { TeamInput } from '@plvs/live-fe/components/event/EventBracketMatchResultModal'
import { Path } from '../paths'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100vw',
  },
  bracketContainer: {
    maxWidth: '1440px',
  },
}))

export const EventPage: React.FC = () => {
  const classes = useStyles()
  const [error, setError] = useState<Error>()
  const [tab, setTab] = useState(1)
  const { id } = useParams()
  const { data, loading, refetch } = useEventQuery({
    variables: {
      id: id ?? '',
    },
    skip: !id,
  })
  const eventData = data?.event
  const bracket = data?.event?.brackets ? data?.event?.brackets[0] : null
  const event = eventData
    ? {
      ...eventData,
      bracketType: bracket?.type ?? BracketType.SingleElimination,
    }
    : undefined

  const [createMutate, { loading: createLoading }] = useCreateEventMutation()
  const [updateMutate, { loading: updateLoading }] = useUpdateEventMutation()
  const [previewBracket] = usePreviewEventBracketMutation()
  const [setTeamsMutate, { loading: setTeamsLoading }] =
    useSetEventTeamsMutation()
  const onEventSubmit = async (input: CreateEventInput): Promise<void> => {
    try {
      if (id) {
        await updateMutate({
          variables: {
            input: { id, event: input, bracketType: input.bracketType },
          },
        })
        await refetch({ id })
      } else {
        const eventId = ((await createMutate({ variables: { input } })) || {})
          ?.data?.createEvent.id

        if (!eventId) {
          throw new Error('Error creating event')
        }
        window.location.replace(generatePath(Path.EventEdit, { id: eventId }))
      }
    } catch (e: any) {
      setError(e)
    }
  }

  const onTeamSubmit = async (
    input: {
      name: string
      ordinalNumber: number
    }[]
  ): Promise<void> => {
    try {
      if (id && event) {
        await setTeamsMutate({
          variables: {
            input: input.map((team) => {
              return {
                name: team.name,
                eventId: id,
                ordinalNumber: team.ordinalNumber,
                seed: Math.max(
                  1,
                  Math.min(team.ordinalNumber, event.defaultBracketSize ?? 1)
                ),
              }
            }),
          },
        })
        if (bracket) {
          await previewBracket({
            variables: { previewEventBracketId: bracket.id },
          })
        }
        await refetch({ id })
      }
    } catch (e: any) {
      setError(e)
    }
  }

  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setTab(value)
  }

  const status = event?.status
  const slug = event?.slug ?? ''
  const currUrl = window.location
  const { copy: copyPublicUrl } = useClipboard(
    `${currUrl.protocol}//${currUrl.host}${generatePath(Path.EventPublic, {
      slug,
    })}`
  )
  const { copy: copyPasswordUrl } = useClipboard(
    `${currUrl.protocol}//${currUrl.host}${generatePath(Path.EventPassword, {
      slug,
    })}`
  )

  const [updateStatusMutation] = useUpdateEventStatusMutation()
  const onUpdateStatus = async (
    id: string,
    status: EventStatus
  ): Promise<void> => {
    await updateStatusMutation({
      variables: {
        input: {
          id,
          status,
        },
      },
    })
    await refetch({ id })
  }

  const [completeMatchMutation] = useCompleteBracketMatchMutation()
  const onCompleteMatch = async (
    matchId: string,
    teams: TeamInput[]
  ): Promise<void> => {
    const input = {
      id: matchId,
      matchTeams: teams.map((team): UpdateBracketMatchTeamInput => {
        return {
          id: team.id,
          matchWinner: team.winner,
          matchScore: team.score,
        }
      }),
    }
    await completeMatchMutation({
      variables: {
        input,
      },
    })
    await refetch({ id })
  }

  const [resetMatchMutation] = useResetBracketMatchMutation()
  const onResetMatch = async (matchId: string): Promise<void> => {
    await resetMatchMutation({
      variables: {
        matchId,
      },
    })
    await refetch({ id })
  }

  const menuItems: { id: string; label: string; onClick: () => void }[] = []
  if (id) {
    if (status === EventStatus.Draft) {
      menuItems.push({
        id: 'start',
        label: 'Start Tournament',
        onClick: async (): Promise<void> =>
          onUpdateStatus(id, EventStatus.Published),
      })
    }
    if (status === EventStatus.Published) {
      menuItems.push({
        id: 'draft',
        label: 'Set to Draft',
        onClick: async (): Promise<void> =>
          onUpdateStatus(id, EventStatus.Draft),
      })
    }
    if (slug) {
      menuItems.push({
        id: 'copy-slug',
        label: 'Copy Bracket Link',
        onClick: copyPublicUrl,
      })
      menuItems.push({
        id: 'copy-slug-password',
        label: 'Copy Admin Bracket',
        onClick: copyPasswordUrl,
      })
    }
  }

  return (
    <AdminPage title="Manage Event">
      {loading || createLoading || updateLoading || setTeamsLoading ? (
        <></>
      ) : (
        <Box className={classes.container}>
          <Box display="flex" flexDirection="column" mr={3} width="530px">
            <Box mb={3}>
              <NxTabs onChange={handleTabChange} value={tab}>
                <NxTab label="1. Bracket Details" size="large" value={1} />
                <NxTab
                  disabled={!id}
                  label="2. Participants"
                  size="large"
                  value={2}
                />
              </NxTabs>
            </Box>
            <NxTypography colorToken="ColorTextError" variant="body3">
              {error?.message}
            </NxTypography>
            {tab === 1 && (
              <EventForm
                cta={id ? 'Update' : 'Save'}
                event={event}
                loading={updateLoading || loading || createLoading}
                onSubmit={onEventSubmit}
              />
            )}
            {tab === 2 && id && (
              <>
                <EventTeamForm
                  eventTeams={event?.teams ?? []}
                  loading={loading || setTeamsLoading}
                  onSubmit={onTeamSubmit}
                />
              </>
            )}
          </Box>
          <Box>
            {bracket && event ? (
              <>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <NxDropdownButton
                    label="Bracket Actions"
                    menuItems={[{ items: menuItems }]}
                    variant="primary"
                  />
                </Box>
                <Box className={classes.bracketContainer} pb={2}>
                  <EventBracketContainer
                    bracket={bracket}
                    editable
                    event={event}
                    eventTeams={event?.teams ?? []}
                    onDelete={onResetMatch}
                    onSubmit={onCompleteMatch}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
    </AdminPage>
  )
}
