import React from 'react'
import { Box, makeStyles, Menu, MenuItem } from '@material-ui/core'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import { Moremenu, TeamsOutlined } from '@playvs-inc/nexus-icons'
import { useClipboard } from '@plvs/live-fe/utils/useClipboard'
import { generatePath, useNavigate } from 'react-router-dom'
import { Path } from '@plvs/live-fe/pages/paths'
import { EventStatus } from '@plvs/live-fe/graphql'
import { EventStatusAvatar } from './EventStatusAvatar'

type Event = {
  id: string
  status: EventStatus | null
  slug: string
  name: string
  esport: string
  defaultBracketSize: number
  seededTeamsCount: number | null
}

interface EventsTableProps {
  events: Event[]
  onUpdateStatus(id: string, status: EventStatus): void
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: '1040px',
  },
  name: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '500px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeaderRow: {
    height: '80px',
  },
  tableRow: {
    height: '80px',
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  header: {
    textAlign: 'left',
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuButton: {
    borderRadius: theme.spacing(4),
    width: theme.spacing(5),
  },
}))

export function EventsTable({
  events,
  onUpdateStatus,
}: EventsTableProps): React.ReactElement {
  const classes = useStyles()
  const navigate = useNavigate()

  const columnHelper = createColumnHelper<Event>()

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Box className={classes.cell}>
          <Box mr={2}>
            <EventStatusAvatar
              id={info.row.original.id}
              status={info.row.original.status}
            />
          </Box>
          <NxTypography
            className={classes.name}
            onClick={(): void => {
              navigate(
                generatePath(Path.EventEdit, { id: info.row.original.id })
              )
            }}
            variant="subtitle1"
          >
            {info.getValue()}
          </NxTypography>
        </Box>
      ),
      header: () => (
        <Box className={classes.header}>
          <NxTypography variant="subtitle1">Name</NxTypography>
        </Box>
      ),
    }),
    columnHelper.accessor('esport', {
      cell: (info) => (
        <Box className={classes.cell}>
          <NxTypography variant="subtitle1">{info.getValue()}</NxTypography>
        </Box>
      ),
      header: () => (
        <Box className={classes.header}>
          <NxTypography variant="subtitle1">Details</NxTypography>
        </Box>
      ),
    }),
    columnHelper.accessor('defaultBracketSize', {
      cell: (info) => (
        <Box className={classes.cell}>
          <Box display="flex" mr={2}>
            <TeamsOutlined />
          </Box>
          <NxTypography variant="subtitle1">
            {`${info.row.original.seededTeamsCount}/${info.getValue()}`}
          </NxTypography>
        </Box>
      ),
      header: () => (
        <Box className={classes.header}>
          <NxTypography variant="subtitle1">Players</NxTypography>
        </Box>
      ),
    }),
    columnHelper.accessor('id', {
      cell: (info) => {
        const id = info.getValue()
        const { slug, status } = info.row.original
        const currUrl = window.location
        const { copy: copyPublicUrl } = useClipboard(
          `${currUrl.protocol}//${currUrl.host}${generatePath(Path.EventPublic, {
            slug,
          })}`
        )
        const { copy: copyPasswordUrl } = useClipboard(
          `${currUrl.protocol}//${currUrl.host}${generatePath(Path.EventPassword, {
            slug,
          })}`
        )
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)
        const handleClick = (
          event: React.MouseEvent<HTMLButtonElement>
        ): void => {
          setAnchorEl(event.currentTarget)
        }
        const handleClose = (): void => {
          setAnchorEl(null)
        }
        return (
          <Box className={classes.cell}>
            <NxIconButton
              className={classes.menuButton}
              icon={<Moremenu />}
              onClick={handleClick}
              variant="text"
            />
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
              onClose={handleClose}
              open={open}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={handleClose}>
                {status === EventStatus.Draft && (
                  <NxTypography
                    onClick={(): void =>
                      onUpdateStatus(id, EventStatus.Published)
                    }
                    variant="body3"
                  >
                    Start Tournament
                  </NxTypography>
                )}
                {status === EventStatus.Published && (
                  <NxTypography
                    onClick={(): void => onUpdateStatus(id, EventStatus.Draft)}
                    variant="body3"
                  >
                    Set to Draft
                  </NxTypography>
                )}
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <NxTypography onClick={copyPublicUrl} variant="body3">
                  Copy Bracket Link
                </NxTypography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <NxTypography onClick={copyPasswordUrl} variant="body3">
                  Copy Admin Bracket
                </NxTypography>
              </MenuItem>
            </Menu>
          </Box>
        )
      },
      header: () => <></>,
    }),
  ]

  const table = useReactTable({
    data: events,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Box className={classes.container}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={classes.tableHeaderRow}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={classes.tableRow}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}
