import React, { useRef } from 'react'
import { Box, FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import { NxIconButton } from '@playvs-inc/nexus-components'
import axios from 'axios'
import { Image } from '@playvs-inc/nexus-icons'

export const MAX_FILE_SIZE_IN_BYTES = 5000000 // 1MB

const useStyles = makeStyles(() => ({
  uploadButton: {},
}))

interface ImageUploadProps {
  onUpload: (fileName: string) => void
  uploadFileMutation: any
  generateUploadUrlMutation: any
  loading: boolean
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  onUpload,
  uploadFileMutation,
  generateUploadUrlMutation,
  loading,
}) => {
  const classes = useStyles()
  const [error, setError] = React.useState<Error>()
  const inputRef = useRef<HTMLInputElement>()

  const handleOnChange = async (file: File | undefined): Promise<void> => {
    setError(undefined)
    try {
      if (file) {
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          throw new Error('File size must be less than 1MB')
        }
        const { data } = await generateUploadUrlMutation({
          variables: {
            input: { contentType: file.type, name: file.name },
          },
        })
        const url = data?.generateUploadUrl.url
        if (url) {
          await axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
          const urlTyped = new URL(url)
          onUpload(`${urlTyped.protocol}//${urlTyped.host}${urlTyped.pathname}`)
        }
      }
    } catch (err: unknown) {
      setError(err as Error)
    }
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const handleOnChangeDirectUpload = async (
    file: File | undefined
  ): Promise<void> => {
    setError(undefined)
    try {
      if (file) {
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          throw new Error('File size must be less than 1MB')
        }
        const { data } = await uploadFileMutation({
          variables: {
            file,
          },
        })
        const url = data?.uploadFile.url ?? ''
        const urlTyped = new URL(url)
        onUpload(`${urlTyped.protocol}//${urlTyped.host}${urlTyped.pathname}`)
      }
    } catch (err: unknown) {
      setError(err as Error)
    }
  }

  const onButtonClick = (): void => {
    inputRef?.current?.click()
  }

  return (
    <Box>
      <FormControl error={!!error} fullWidth>
        <NxIconButton
          className={classes.uploadButton}
          disabled={loading}
          icon={<Image />}
          label={loading ? 'Uploading...' : 'Upload'}
          loading={loading}
          onClick={onButtonClick}
          variant="secondary"
        />
        <input
          // @ts-ignore HTMLInputElement is a valid ref type
          ref={inputRef}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
            handleOnChangeDirectUpload(e?.target?.files?.[0])
          }
          style={{ display: 'none' }}
          type="file"
        />
        <Box mt={1}>
          <FormHelperText>{error && error.message}</FormHelperText>
        </Box>
      </FormControl>
    </Box>
  )
}
