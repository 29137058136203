import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, makeStyles } from '@material-ui/core'
import {
  NxButton,
  NxSelect,
  NxSelectOption,
  NxTextInput,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { isInputValid } from '@plvs/live-fe/utils/formUtils'
import * as yup from 'yup'
import {
  BracketType,
  useGenerateUploadUrlMutation,
  useUploadFileMutation,
} from '@plvs/live-fe/graphql'
import { ImageUpload } from './ImageUpload'
import { Hide, Unhide } from '@playvs-inc/nexus-icons'

const useStyles = makeStyles((theme) => ({
  formCard: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    padding: theme.spacing(3),
  },
  thumbnail: {
    width: '100%',
  },
}))

const EventFormSchema = yup.object().shape({
  bannerUrl: yup.string(),
  bracketBackgroundUrl: yup.string(),
  defaultBracketSize: yup.number().required('Select a bracket size.'),
  esport: yup.string().required('Enter details for the event.'),
  name: yup.string().required('Enter a name for the event.'),
  slug: yup.string().required('Enter a public slug for the event.'),
  themeColorCode: yup.string().required('Enter a color theme for the event.'),
  resultSubmissionPassword: yup.string(),
})

type EventInput = {
  name: string
  esport: string
  defaultBracketSize: number
  slug: string
  bannerUrl: string | null
  bracketBackgroundUrl: string | null
  resultSubmissionPassword: string | null
  themeColorCode: string
  bracketType: BracketType
}

interface EventFormProps {
  event?: EventInput
  onSubmit(input: EventInput): void
  cta: string
  loading: boolean
}

const cleanupValues = (input?: EventInput): EventInput => {
  return {
    name: input?.name ?? '',
    esport: input?.esport ?? '',
    slug: input?.slug ?? '',
    themeColorCode: input?.themeColorCode ?? '#ff5509',
    defaultBracketSize: input?.defaultBracketSize ?? 8,
    bannerUrl: input?.bannerUrl ?? '',
    bracketBackgroundUrl: input?.bracketBackgroundUrl ?? '',
    bracketType: input?.bracketType ?? BracketType.SingleElimination,
    resultSubmissionPassword: input?.resultSubmissionPassword ?? '',
  }
}

export const EventForm: React.FC<EventFormProps> = ({
  event,
  onSubmit,
  cta,
  loading,
}) => {
  const classes = useStyles()
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<EventInput>({
    defaultValues: cleanupValues(event),
  })

  const isValid = isInputValid(watch(), EventFormSchema)
  const handle = handleSubmit(async (input: EventInput): Promise<void> => {
    onSubmit(input)
  })

  const [showPassword, setShowPassword] = React.useState(false)
  const [bannerUrl, setBannerUrl] = useState<string>(event?.bannerUrl ?? '')
  const [bracketUrl, setBracketUrl] = useState<string>(
    event?.bracketBackgroundUrl ?? ''
  )
  const [uploadFileMutation, { loading: isUploading }] = useUploadFileMutation()
  const [generateUploadUrlMutation, { loading: isGenerating }] =
    useGenerateUploadUrlMutation()

  const canSubmit = isValid && !isUploading && !isGenerating && !loading
  const ShowPasswordIcon = showPassword ? Unhide : Hide

  useEffect(() => {
    reset(cleanupValues(event))
  }, [event])

  return (
    <Box
      className={classes.formCard}
      component="form"
      /* @ts-expect-error: valid prop */
      method="post"
      onSubmit={handle}
    >
      <Box mb={3}>
        <NxTypography variant="h3">Event Information</NxTypography>
      </Box>
      <Box mb={3}>
        <NxTextInput
          {...register('name')}
          autoComplete="off"
          fullWidth
          helperText={errors.name?.message?.toString()}
          label="Event Name"
          name="name"
          type="text"
          variant={errors.name ? 'error' : 'default'}
        />
      </Box>
      <Box mb={3}>
        <NxTextInput
          {...register('esport')}
          autoComplete="off"
          fullWidth
          helperText={errors.esport?.message?.toString()}
          label="Event Details"
          name="esport"
          type="text"
          variant={errors.name ? 'error' : 'default'}
        />
      </Box>
      <Box mb={3}>
        <NxSelect
          {...register('bracketType')}
          defaultValue={getValues().bracketType}
          fullWidth
          label="Bracket Type"
        >
          <NxSelectOption
            key="singleElimination"
            selected
            value="singleElimination"
          >
            Single Elimination
          </NxSelectOption>
          <NxSelectOption
            key="doubleElimination"
            selected
            value="doubleElimination"
          >
            Double Elimination
          </NxSelectOption>
        </NxSelect>
      </Box>
      <Box mb={3}>
        <NxSelect
          {...register('defaultBracketSize')}
          defaultValue={getValues().defaultBracketSize}
          fullWidth
          helperText={errors.defaultBracketSize?.message?.toString()}
          label="Bracket Size"
          name="defaultBracketSize"
        >
          <NxSelectOption key={4} value={4}>
            4
          </NxSelectOption>
          <NxSelectOption key={8} value={8}>
            8
          </NxSelectOption>
          <NxSelectOption key={16} value={16}>
            16
          </NxSelectOption>
          <NxSelectOption key={32} value={32}>
            32
          </NxSelectOption>
          <NxSelectOption key={64} value={64}>
            64
          </NxSelectOption>
        </NxSelect>
      </Box>
      <Box mb={3}>
        <NxTextInput
          {...register('slug')}
          autoComplete="off"
          fullWidth
          helperText={errors.slug?.message?.toString()}
          label="Public Page Slug"
          name="slug"
          type="text"
          variant={errors.name ? 'error' : 'default'}
        />
      </Box>
      <Box mb={3}>
        <NxTextInput
          {...register('resultSubmissionPassword')}
          autoComplete="off"
          endAdornment={
            <ShowPasswordIcon
              data-cy="showPasswordIcon"
              data-testid="showPassword"
              onClick={(): void => setShowPassword(!showPassword)}
            />
          }
          fullWidth
          helperText={errors.resultSubmissionPassword?.message?.toString()}
          label="Admin Bracket Password"
          name="resultSubmissionPassword"
          type={showPassword ? 'text' : 'password'}
          variant={errors.name ? 'error' : 'default'}
        />
      </Box>
      <Box mb={3}>
        <NxTypography variant="body2">Event Banner</NxTypography>
        {bannerUrl ? (
          <img alt="Banner" className={classes.thumbnail} src={bannerUrl} />
        ) : (
          <></>
        )}
        <ImageUpload
          generateUploadUrlMutation={generateUploadUrlMutation}
          loading={isUploading || isGenerating}
          onUpload={(url: string): void => {
            setValue('bannerUrl', url)
            setBannerUrl(url)
          }}
          uploadFileMutation={uploadFileMutation}
        />
        <NxTypography variant="body5">
          Recommended Banner Size 1024x100
        </NxTypography>
      </Box>
      <Box mb={3}>
        <NxTypography variant="body2">Bracket Background Image</NxTypography>
        {bracketUrl ? (
          <img
            alt="Background"
            className={classes.thumbnail}
            src={bracketUrl}
          />
        ) : (
          <></>
        )}
        <ImageUpload
          generateUploadUrlMutation={generateUploadUrlMutation}
          loading={isUploading || isGenerating}
          onUpload={(url: string): void => {
            setValue('bracketBackgroundUrl', url)
            setBracketUrl(url)
          }}
          uploadFileMutation={uploadFileMutation}
        />
        <NxTypography variant="body5">
          Recommended Background Image Size 1024x400
        </NxTypography>
      </Box>
      <Box mb={3}>
        <NxTypography variant="body2">Color Theme</NxTypography>
        <NxTextInput
          {...register('themeColorCode')}
          autoComplete="off"
          fullWidth
          helperText={errors.themeColorCode?.message?.toString()}
          label=""
          name="themeColorCode"
          type="color"
          variant={errors.name ? 'error' : 'default'}
        />
      </Box>
      <Box>
        <NxButton
          disabled={!canSubmit}
          fullWidth
          label={cta}
          type="submit"
          variant="primary"
        />
      </Box>
    </Box>
  )
}
