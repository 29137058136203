import { Box, ThemeProvider, makeStyles } from '@material-ui/core'
import { EventBracketContainer } from '@plvs/live-fe/components/event/EventBracketContainer'
import { Page } from '@plvs/live-fe/components/layout/Page'
import { UpdateBracketMatchTeamInput, useCompleteBracketMatchWithPasswordMutation, useEventBySlugWithPasswordQuery, useResetBracketMatchWithPasswordMutation } from '@plvs/live-fe/graphql'
import React, { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  NxButton,
  NxEmptyState,
  NxModal,
  NxTextInput,
} from '@playvs-inc/nexus-components'
import PlayVSBanner from '@plvs/live-fe/assets/PlayVSBanner.svg'
import { useImageLoader } from '@plvs/live-fe/utils/imageUtils'
import { NxSpot } from '@playvs-inc/nexus-spots'
import { PlayVsDarkTheme } from '@playvs-inc/nexus-theme'
import { TeamInput } from '@plvs/live-fe/components/event/EventBracketMatchResultModal'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#161616',
    minHeight: '100vh',
  },
  laterContainer: {
    width: '100vw',
    '& img': {
      objectFit: 'cover',
      width: '100%',
    },
  },
  emptyState: {
    backgroundColor: '#161616',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}))

export const EventPasswordPage: React.FC = () => {
  const classes = useStyles()
  const { slug } = useParams()
  const [inputPassword, setInputPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [open, setOpen] = useState<boolean>(true)
  const { data, loading, error, refetch } = useEventBySlugWithPasswordQuery({
    variables: {
      slug: slug ?? '',
      password: password ?? '',
    },
    skip: !slug || !password,
  })
  const event = data?.eventBySlugWithPassword
  const bracket = event?.brackets
    ? event?.brackets[0]
    : null
  const { imageSrc: bannerImageSrc } = useImageLoader({
    src: PlayVSBanner,
  })

  const [completeMatchMutation] = useCompleteBracketMatchWithPasswordMutation()
  const onCompleteMatch = async (
    matchId: string,
    teams: TeamInput[]
  ): Promise<void> => {
    const input = {
      id: matchId,
      matchTeams: teams.map((team): UpdateBracketMatchTeamInput => {
        return {
          id: team.id,
          matchWinner: team.winner,
          matchScore: team.score,
        }
      }),
    }
    await completeMatchMutation({
      variables: {
        input,
        password,
      },
    })
    await refetch({ slug, password })
  }

  const [resetMatchMutation] = useResetBracketMatchWithPasswordMutation()
  const onResetMatch = async (matchId: string): Promise<void> => {
    await resetMatchMutation({
      variables: {
        matchId,
        password,
      },
    })
    await refetch({ slug, password })
  }

  const onClose = (): void => {
    setOpen(false)
  }

  return (
    <Page title="Event">
      {loading ? (
        <></>
      ) : (
        <Box className={classes.container}>
          {!bracket || !event || error ? (
            <Box className={classes.laterContainer}>
              <img alt="Live Banner" src={bannerImageSrc} />
              <ThemeProvider theme={PlayVsDarkTheme}>
                <NxEmptyState
                  className={classes.emptyState}
                  cta={
                    <NxButton
                      label="Refresh Page"
                      onClick={(): void => {
                        window.location.reload()
                      }}
                      variant="secondary"
                    />
                  }
                  data-testid="empty"
                  spot={
                    <NxSpot
                      domain="Arena"
                      height={132}
                      size="large"
                      variant="secondary"
                      width={132}
                    />
                  }
                  subtitle="This event does not exist or the password was incorrect. Please try again."
                  title="Event Not Found"
                />
              </ThemeProvider>
            </Box>
          ) : (
            <EventBracketContainer
              bracket={bracket}
              editable
              event={event}
              eventTeams={event?.teams ?? []}
              onDelete={onResetMatch}
              onSubmit={onCompleteMatch}
            />
          )}
        </Box>
      )}
      <NxModal
        onClose={onClose}
        open={open}
        size="small"
        title="Enter Password"
      >
        <Box
          component="form"
          onSubmit={(e): void => {
            e.preventDefault()
            setOpen(false)
            setPassword(inputPassword)
          }}
        >
          <NxTextInput
            autoComplete="off"
            fullWidth
            label="Admin Bracket Password"
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ): void => {
              setInputPassword(evt.currentTarget.value)
            }}
            type="password"
            variant="default"
          />
          <NxButton
            label="Submit"
            type="submit"
            variant="primary"
          />
        </Box>
      </NxModal>
    </Page>
  )
}
