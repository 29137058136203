import { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 960px
// lg, large: 1280px
// xl, extra-large: 1920px

const DEFAULT_DEBOUNCE_TIME = 500

export const useWindowDimensions = (): { width: number; height: number } => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleResize = useDebouncedCallback((): void => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }, DEFAULT_DEBOUNCE_TIME).call

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  return {
    width,
    height,
  }
}

export const useBreakpointXs = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('xs'))
}

export const useBreakpointSm = (): boolean =>
  useMediaQuery(useTheme().breakpoints.down('sm'), { noSsr: true })

export const useBreakpointMd = (): boolean =>
  useMediaQuery(useTheme().breakpoints.down('md'))

export const useBreakpointLg = (): boolean =>
  useMediaQuery(useTheme().breakpoints.down('lg'))

export const useBreakpointXl = (): boolean =>
  useMediaQuery(useTheme().breakpoints.down('xl'))
