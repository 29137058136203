import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import RedCircle from '@plvs/live-fe/assets/RedCircle.svg'
import GreenCircle from '@plvs/live-fe/assets/GreenCircle.svg'
import GrayCircle from '@plvs/live-fe/assets/GrayCircle.svg'

import { NxAvatar } from '@playvs-inc/nexus-components'
import { EventStatus } from '@plvs/live-fe/graphql'

const useStyles = makeStyles(() => ({
  circle: {
    position: 'absolute',
    bottom: 2,
    right: 2,
  },
}))

const statusMap = {
  published: RedCircle,
  archived: GrayCircle,
  draft: GreenCircle,
}

export const EventStatusAvatar: React.FC<{
  id: string
  status: EventStatus | null
}> = ({ id, status }) => {
  const classes = useStyles()
  const circle = status ? statusMap[status] ?? GrayCircle : undefined
  return (
    <Box position="relative">
      <NxAvatar avatarHashId={id} />
      {status && <img alt="Status" className={classes.circle} src={circle} />}
    </Box>
  )
}
