import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { BracketTeam, EventBracketTeam } from './EventBracketTeam'
import {
  TeamInput,
} from './EventBracketMatchResultModal'

const useStyles = makeStyles(() => ({
  match: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#000',
    width: '363px',
    minHeight: '158px',
    paddingBottom: '17.44px',
  },
  matchHeader: { textAlign: 'end' },
  matchFooter: { textAlign: 'end' },
}))

export type BracketMatch = {
  id: string
  name: string
}

export interface BracketMatchProps {
  match: BracketMatch
  topParty: BracketTeam
  bottomParty: BracketTeam
  themeColorCode: string
  onClick?: (matchId: string, teams: TeamInput[]) => void
}

export function EventBracketMatch({
  match,
  topParty,
  bottomParty,
  themeColorCode,
  onClick,
}: BracketMatchProps): React.ReactElement {
  const classes = useStyles()

  return (
    <>
      <Box
        className={classes.match}
        onClick={() => {
          if (onClick) {
            onClick(match.id, [topParty, bottomParty])
          }
        }}
      >
        <Box className={classes.matchHeader}>
          <NxTypography colorToken="OverlayColorTextAlt" variant="body4">
            {match.name}
          </NxTypography>
        </Box>
        <Box mb={1}>
          <EventBracketTeam themeColorCode={themeColorCode} party={topParty} />
        </Box>
        <Box>
          <EventBracketTeam themeColorCode={themeColorCode} party={bottomParty} />
        </Box>
      </Box>
    </>
  )
}
