import React from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { PlayVsTheme } from '@playvs-inc/nexus-theme'
import { BrowserRouter } from 'react-router-dom'
import { Pages } from './pages'

const App = (): React.ReactElement => {
  return (
    <>
      <ThemeProvider theme={PlayVsTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Pages />
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
